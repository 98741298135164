import { useRef } from "react";
import Axios from "axios";

/**
 * Hook creates Axios' source as an instance variable using `useRef` hook.
 * @returns {{cancel: (function(): void), setSource: (function(): void), getSource: (function(): React.MutableRefObject<null>), getToken: (function(): (CancelToken | undefined))}}
 */
export const useAxiosSource = () => {
  const source = useRef(null);

  const getSource = () => {
    return source.current;
  };

  const setSource = () => {
    source.current = Axios.CancelToken.source();
  };

  const cancel = () => {
    source?.current?.cancel();
  };

  const getToken = () => {
    return source?.current?.token;
  };

  return {
    getSource,
    setSource,
    cancel,
    getToken,
  };
};
