import { PropTypes } from "prop-types";
import AUFlag from "../../../assets/svg/flags/AUFlag.svg";
import BLFlag from "../../../assets/svg/flags/BLFlag.svg";
import CAFlag from "../../../assets/svg/flags/CAFlag.svg";
import DEFlag from "../../../assets/svg/flags/DEFlag.svg";
import GlobeFlag from "../../../assets/svg/flags/GlobeFlag.svg";
import IDFlag from "../../../assets/svg/flags/IDFlag.svg";
import INFlag from "../../../assets/svg/flags/INFlag.svg";
import MXFlag from "../../../assets/svg/flags/MXFlag.svg";
import NAFlag from "../../../assets/svg/flags/NAFlag.svg";
import NZFlag from "../../../assets/svg/flags/NZFlag.svg";
import PFlag from "../../../assets/svg/flags/PFlag.svg";
import SGFlag from "../../../assets/svg/flags/SGFlag.svg";
import UKFlag from "../../../assets/svg/flags/UKFlag.svg";
import USFlag from "../../../assets/svg/flags/USFlag.svg";
import ESFlag from "../../../assets/svg/flags/ESFlag.svg";
import FRFlag from "../../../assets/svg/flags/FRFlag.svg";
import VNFlag from "../../../assets/svg/flags/VNFlag.svg";
import HKFlag from "../../../assets/svg/flags/HKFlag.svg";
import THFlag from "../../../assets/svg/flags/THFlag.svg";
import MAFlag from "../../../assets/svg/flags/MAFlag.svg";
import JPFlag from "../../../assets/svg/flags/JPFlag.svg";
import TWFlag from "../../../assets/svg/flags/TWFlag.svg";

export const RegionLogo = ({ name, className }) => {
  switch (name) {
    case "Australia": {
      return <img className={className} src={AUFlag.src} alt="" height="18" width="24" />;
    }
    case "Belarus": {
      return <img className={className} src={BLFlag.src} alt="" height="18" width="24" />;
    }
    case "Canada": {
      return <img className={className} src={CAFlag.src} alt="" height="18" width="24" />;
    }
    case "Germany": {
      return <img className={className} src={DEFlag.src} alt="" height="18" width="24" />;
    }
    case "Global": {
      return <img className={className} src={GlobeFlag.src} alt="" height="18" width="24" />;
    }
    case "Indonesia": {
      return <img className={className} src={IDFlag.src} alt="" height="18" width="24" />;
    }
    case "India": {
      return <img className={className} src={INFlag.src} alt="" height="18" width="24" />;
    }
    case "Mexico": {
      return <img className={className} src={MXFlag.src} alt="" height="18" width="24" />;
    }
    case "North America": {
      return <img className={className} src={NAFlag.src} alt="" height="18" width="24" />;
    }
    case "New Zealand": {
      return <img className={className} src={NZFlag.src} alt="" height="18" width="24" />;
    }
    case "Phillippines": {
      return <img className={className} src={PFlag.src} alt="" height="18" width="24" />;
    }
    case "Singapore": {
      return <img className={className} src={SGFlag.src} alt="" height="18" width="24" />;
    }
    case "Spain": {
      return <img className={className} src={ESFlag.src} alt="" height="18" width="24" />;
    }
    case "France": {
      return <img className={className} src={FRFlag.src} alt="" height="18" width="24" />;
    }
    case "Vietnam": {
      return <img className={className} src={VNFlag.src} alt="" height="18" width="24" />;
    }
    case "Hong Kong": {
      return <img className={className} src={HKFlag.src} alt="" height="18" width="24" />;
    }
    case "Thailand": {
      return <img className={className} src={THFlag.src} alt="" height="18" width="24" />;
    }
    case "United Kingdom": {
      return <img className={className} src={UKFlag.src} alt="" height="18" width="24" />;
    }
    case "United States": {
      return <img className={className} src={USFlag.src} alt="" height="18" width="24" />;
    }
    case "Malaysia": {
      return <img className={className} src={MAFlag.src} alt="" height="18" width="24" />;
    }
    case "Japan": {
      return <img className={className} src={JPFlag.src} alt="" height="18" width="24" />;
    }
    case "Taiwan": {
      return <img className={className} src={TWFlag.src} alt="" height="18" width="24" />;
    }
    default: {
      return null;
    }
  }
};

RegionLogo.propTypes = {
  name: PropTypes.string,
};
