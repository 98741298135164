import { useEffect, useRef, useState, Fragment, useMemo } from "react";
import clsx from "clsx";

import { useViewport } from "../../utils/useViewport";
import { DemystLogo } from "../../assets/svg/DemystLogo";

import {
  FiHome,
  FiActivity,
  FiBook,
  FiBookOpen,
  FiCode,
  FiDatabase,
  FiHelpCircle,
  FiMenu,
  FiSettings,
  FiX,
  FiPieChart,
  FiZap,
  FiZoomIn,
} from "react-icons/fi";
import { linksConstants } from "../../../constants/linksConstants";
import { userPermissions } from "../../../config/appConfig";
import { MainNav } from "./MainNav";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../redux/user/userSelectors";
import difference from "lodash.difference";
import { toArray } from "../../../utils/helpers";
import { KnowledgeCenterSubNav } from "./subNavs/KnowledgeCenterSubNav";
import { useShowPricing } from "../../../utils/hooks/useShowPricing";
import Link from "next/link";

import { footerMenu } from "../../../landingPage/datasets/footerMenu";
import { useRouter } from "next/router";
import { matchPath } from "./utils";

export const mainNavConfiguration = [
  {
    label: "Home",
    path: linksConstants.PLATFORM_HOME,
    logo: <FiHome />,
  },
  {
    label: "Connectors",
    path: linksConstants.DATA_CATALOG,
    logo: <FiBookOpen />,
    skip_before_action_authenticate_user: true,
  },
  {
    label: "Data APIs",
    path: linksConstants.USE_CASES,
    logo: <FiActivity />,
  },
  {
    label: "Projects",
    path: linksConstants.PROJECTS_ALL,
    logo: <FiBook />,
  },
  {
    label: "Data Shares",
    path: linksConstants.DATA_SHARE,
    logo: <FiPieChart />,
    permissions: [userPermissions.canShowAdmin],
  },
  {
    label: "Internal Data",
    path: linksConstants.INTERNAL_DATA_CONNECTORS,
    logo: <FiZap />,
  },
  {
    label: "Admin",
    path: linksConstants.SETTINGS,
    logo: <FiSettings />,
    permissions: [userPermissions.canCreateUsers],
  },
  {
    label: "File Transfers",
    path: linksConstants.FILE_TRANSFERS,
    logo: <FiDatabase />,
    permissions: [userPermissions.canShowFileTransfers],
    // subNav: FileTransfersSubNav,
  },
  {
    label: "Knowledge Center",
    path: linksConstants.KNOWLEDGE_CENTER,
    logo: <FiHelpCircle />,
    show: false,
    subNav: KnowledgeCenterSubNav,
  },
  {
    label: "Documentation",
    path: `https://docs.demyst.com/`,
    logo: <FiCode />,
    target_blank: true,
    skip_before_action_authenticate_user: true,
  },
  {
    label: "Reports Monitor",
    path: linksConstants.REPORTS_MONITOR,
    logo: <FiZoomIn />,
  },
];

const isItemEnabled = (item, user) => {
  if (!user && !item.skip_before_action_authenticate_user) {
    return false;
  }

  return item.enabled !== false;
};

const checkUserPermissions = (item, userPermissions) => {
  // if item has no permissions specified - pass;
  if (!item.permissions?.length) return true;
  // if item has permissions specified but no user permissions passed - fail;
  if (!userPermissions) return false;

  // filter user permissions and create an array consisting of permissions that are set to true
  const usersTruePermissions = Object.keys(userPermissions).filter(key => userPermissions[key]);
  // calculate difference between item's specified permissions and user permissions;
  const permissionsDifference = difference(item.permissions, usersTruePermissions);
  // if there is no difference between permissions, `permissionsDifference` should be equal to
  // an empty array.
  return Array.isArray(permissionsDifference) && permissionsDifference.length === 0;
};

const checkUserPlan = (item, userPlan) => {
  // if item has no plan specified - pass;
  if (!item.permittedUserPlans?.length) return true;
  // if item has plans specified but no user plan passed - fail;
  if (!userPlan) return false;

  // check if any of permitted plans equals to user's plan
  return item.permittedUserPlans.some(plan => plan === userPlan);
};

const isItemVisible = (item, user, userPlan, userData) => {
  if (item.label === "Data Shares") {
    if (userData?.data_share !== true) {
      return false;
    }
  }
  if (typeof window === "undefined") return false;
  if (
    (item.path === linksConstants.PROJECTS_ALL &&
      window.localStorage.getItem("projects_visible") !== "true") ||
    (item.path === linksConstants.INTERNAL_DATA_CONNECTORS &&
      window.localStorage.getItem("internal_data_visible") !== "true") ||
    (item.path === linksConstants.REPORTS_MONITOR &&
      window.localStorage.getItem("reports_monitor") !== "true")
  ) {
    return false;
  }

  // if `show` is set to `false` in configuration, never show this item;

  if (item.show === false) {
    return false;
  }

  if (user == null) {
    return item.skip_before_action_authenticate_user;
  }

  const permissionsCheckPassed = checkUserPermissions(item, user.permissions);
  const planCheckPassed = checkUserPlan(item, userPlan);

  return permissionsCheckPassed && planCheckPassed;
};

export const parseNavConfigurationItem = (item, user, userPlan, userData) => {
  return {
    ...item,
    enabled: isItemEnabled(item, user),
    show: isItemVisible(item, user, userPlan, userData),
  };
};

// Simple wrapper over react router`s `matchPath` function. Checks if any of the urls provided
// is currently active.
export const isNavItemActive = ({ urls, pathname, exact = false }) => {
  return toArray(urls).some(url => matchPath(pathname, { path: url, exact }));
};

export const Navigation = () => {
  const [showNavigation, setShowNavigation] = useState(true);
  const { tablet_landscape } = useViewport();
  const userData = useSelector(userSelectors.getUserData);
  const userPlan = useSelector(userSelectors.getUserPlan);
  const showPricing = useShowPricing();
  const router = useRouter();
  let currentYear = new Date().getFullYear();

  useEffect(() => {
    if (tablet_landscape) {
      setShowNavigation(false);
    } else {
      setShowNavigation(true);
    }
  }, [tablet_landscape]);

  const toggleNavigation = () => {
    setShowNavigation(value => !value);
  };

  const closeNavigation = () => {
    if (tablet_landscape) {
      setShowNavigation(false);
    }
  };

  const parsedMainNavConfig = mainNavConfiguration.map(item => {
    if (item.label === "Billing") item.enabled = showPricing;
    if (item.label === "Billing" && userPlan === "unverified") item.enabled = false;
    return parseNavConfigurationItem(item, userData.user, userPlan, userData);
  });

  const activeMainNavItem = useMemo(() => {
    return parsedMainNavConfig.find(item =>
      isNavItemActive({
        urls: [item.path],
        pathname: router.asPath,
      }),
    );
  }, [router.asPath, parsedMainNavConfig]);

  const prevFirstPath = useRef();
  const prevSecondPath = useRef();

  useEffect(() => {
    const [, , firstPath, secondPath] = window.location.pathname.split("/");
    // Only close navigation if second path and second path only changed
    if (firstPath === prevFirstPath.current && prevSecondPath.current !== undefined) {
      closeNavigation();
    }

    prevFirstPath.current = firstPath;
    prevSecondPath.current = secondPath;
  }, [router.asPath]);

  return (
    <>
      <div
        className={clsx("NavigationWrapper", showNavigation && "NavigationWrapper--showNavigation")}
      >
        <div className="Navigation">
          <div className="Navigation__header">
            <Link href={userData.user ? linksConstants.USE_CASES : "/"}>
              <DemystLogo />
            </Link>
          </div>
          <MainNav mainNavConfig={parsedMainNavConfig} closeNavigation={closeNavigation} />
          {activeMainNavItem?.subNav && <activeMainNavItem.subNav />}
          <div className="Navigation__footer">
            <div className="Navigation__footer__content">
              <span>© {currentYear} DemystData, Ltd</span>
              {footerMenu.columns
                .filter(c => c.header === "Legal")
                .map(c =>
                  c.items
                    .filter(l => !l.hideShort)
                    .map((link, i) => (
                      <Fragment key={link.name}>
                        {i !== 0 && <div className="Navigation__footer__content__spacer" />}
                        <Link target="_blank" href={link.url}>
                          {link.shortName}
                        </Link>
                      </Fragment>
                    )),
                )}
            </div>
          </div>
        </div>
      </div>
      <button
        className={clsx(
          "NavigationMobileToggleButton",
          showNavigation && "NavigationMobileToggleButton--active",
        )}
        onClick={toggleNavigation}
        title={showNavigation ? "Close" : "Menu"}
      >
        {showNavigation ? <FiX /> : <FiMenu />}
      </button>
    </>
  );
};

Navigation.propTypes = {};
