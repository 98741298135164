import { forwardRef, useState } from "react";
import { PropTypes } from "prop-types";
import ClickAwayListener from "react-click-away-listener";
import clsx from "clsx";
import { FiLogOut, FiUser, FiX, FiActivity, FiMenu, FiCopy, FiShuffle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

import { useViewport } from "../utils/useViewport";
import { appConfig, animatePresenceConfig } from "../../config/appConfig";
import { userSelectors } from "../../redux/user/userSelectors";
import { userThunks } from "../../redux/user/userSlice";
import { DevTools } from "./DevTools";
import { linksConstants } from "../../constants/linksConstants";
import Link from "next/link";
import { userApi } from "../../api/userApi";
import { notify } from "../utils/notify";
import copy from "copy-to-clipboard";

const UserMenuContent = ({ closeUserMenu }) => {
  const userData = useSelector(userSelectors.getUserData);
  const dispatch = useDispatch();

  const isAdminUserUsingAccountInClientOrganization =
    !!userData?.user?.created_by_administrative_user;

  const isDemystOrg = userData?.organization?.is_demyst_org;
  let isAdmin = useSelector(userSelectors.isAdmin);

  const menuItems = [
    {
      label: isAdminUserUsingAccountInClientOrganization
        ? `Quit back to ${userData.user.created_by_administrative_user.email}`
        : "Log Out",
      icon: <FiLogOut />,
      onClick: () => {
        if (isAdminUserUsingAccountInClientOrganization) {
          const password = prompt(
            "Hold up 🚨 To go back to your admin account, we gonna need yo password 🔐",
          );
          dispatch(
            userThunks.logoutAdminUserUsingAccountInClientOrganization({
              organization_id: userData.organization.id,
              password,
            }),
          );
        } else {
          dispatch(userThunks.logoutUser());
        }
        closeUserMenu();
      },
    },
  ];

  return (
    <div className="UserMenuContent">
      <div className="UserMenuContent__list">
        {isDemystOrg && isAdmin && (
          <a
            className="UserMenuContent__list__item"
            href={`${appConfig.DEMYST_CONSOLE_API_URL}/admin`}
            rel="noopener noreferrer"
          >
            <FiActivity />
            <span className="UserMenuContent__list__item--label">Admin Tools</span>
          </a>
        )}

        <Link className="UserMenuContent__list__item" href={linksConstants.SETTINGS_PROFILE}>
          <FiUser />
          <span className="UserMenuContent__list__item--label">Profile</span>
        </Link>

        <button
          className="UserMenuContent__list__item"
          href="#"
          onClickCapture={async e => {
            e.preventDefault();
            const { data } = await userApi.getJWT();
            const { jwt } = data;
            copy(jwt);
            notify.success(`JWT copied to clipboard`);
          }}
        >
          <FiCopy />
          <span className="UserMenuContent__list__item--label">Copy JWT</span>
        </button>

        {userData?.organization?.organizations_allowed_to_switch_to?.length ? (
          <Link className="UserMenuContent__list__item" href={linksConstants.SWITCH_ORGANIZATON}>
            <FiShuffle />
            <span className="UserMenuContent__list__item--label">Switch Organization</span>
          </Link>
        ) : null}

        {menuItems.map(item => {
          return (
            <button key={item.label} className="UserMenuContent__list__item" onClick={item.onClick}>
              {item.icon}
              <span className="UserMenuContent__list__item--label">{item.label}</span>
            </button>
          );
        })}
      </div>
      <DevTools />
    </div>
  );
};

UserMenuContent.propTypes = {
  closeUserMenu: PropTypes.func.isRequired,
};

export const UserMenuPopup = forwardRef(({ closeUserMenu }, ref) => {
  return (
    <motion.div ref={ref} className="UserMenuPopup" {...animatePresenceConfig}>
      <UserMenuContent closeUserMenu={closeUserMenu} />
    </motion.div>
  );
});

UserMenuPopup.displayName = "UserMenuPopup";

UserMenuPopup.propTypes = {
  closeUserMenu: PropTypes.func,
};

export const UserMenuFullScreen = ({ closeUserMenu }) => {
  return (
    <motion.div className="UserMenuFullScreen" {...animatePresenceConfig}>
      <div className="UserMenuFullScreen__header">
        <button
          className="UserMenuFullScreen__header__closeButton"
          onClick={closeUserMenu}
          title="Close"
        >
          <FiX />
        </button>
      </div>
      <UserMenuContent closeUserMenu={closeUserMenu} />
    </motion.div>
  );
};

UserMenuFullScreen.propTypes = {
  closeUserMenu: PropTypes.func,
};

export const UserMenu = () => {
  const userData = useSelector(userSelectors.getUserData);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { mobile } = useViewport();

  const toggleUserMenu = () => {
    setShowUserMenu(value => !value);
  };

  const closeUserMenu = () => {
    setShowUserMenu(false);
  };

  return (
    <div className="UserMenu">
      <button
        className={clsx("UserMenu__button", showUserMenu && "UserMenu__button--active")}
        onClick={toggleUserMenu}
      >
        <div className="UserMenu__button__logo">
          {userData?.organization?.collapsed_logo_url ? (
            <img
              src={userData?.organization?.collapsed_logo_url}
              alt={userData?.organization?.name}
            />
          ) : (
            <FiUser />
          )}
        </div>
        <div className="UserMenu__button__symbol">
          <FiMenu />
        </div>
        <div className="UserMenu__button__info">
          <span>{userData?.user?.name}</span>
          <span>{userData?.organization?.name}</span>
        </div>
      </button>
      <AnimatePresence>
        {showUserMenu ? (
          mobile ? (
            <UserMenuFullScreen closeUserMenu={closeUserMenu} />
          ) : (
            <ClickAwayListener onClickAway={closeUserMenu}>
              <UserMenuPopup closeUserMenu={closeUserMenu} />
            </ClickAwayListener>
          )
        ) : null}
      </AnimatePresence>
    </div>
  );
};
