import { SubNav } from "./SubNav";
import { linksConstants } from "../../../../constants/linksConstants";
import { SubNavItem } from "./SubNavItem";
import { parseNavConfigurationItem } from "../Navigation";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../../redux/user/userSelectors";

const knowledgeCenterSubNavConfig = [
  {
    label: "All Categories",
    path: linksConstants.KNOWLEDGE_CENTER,
    exact: true,
  },
  {
    label: "Demyst Solutions",
    path: `${linksConstants.KNOWLEDGE_CENTER}/category/solutions`,
    activeUrl: `${linksConstants.KNOWLEDGE_CENTER}/solutions`,
    exact: false,
  },
  {
    label: "Discovery",
    path: `${linksConstants.KNOWLEDGE_CENTER}/category/discovery`,
    activeUrl: `${linksConstants.KNOWLEDGE_CENTER}/discovery`,
    exact: false,
  },
  {
    label: "Production",
    path: `${linksConstants.KNOWLEDGE_CENTER}/category/production`,
    activeUrl: `${linksConstants.KNOWLEDGE_CENTER}/production`,
    exact: false,
  },
  {
    label: "Compliance",
    path: `${linksConstants.KNOWLEDGE_CENTER}/category/compliance`,
    activeUrl: `${linksConstants.KNOWLEDGE_CENTER}/compliance`,
    exact: false,
  },
];

export const KnowledgeCenterSubNav = () => {
  const userData = useSelector(userSelectors.getUserData);
  const parsedKnowledgeCenterSubNavConfig = knowledgeCenterSubNavConfig.map(item =>
    parseNavConfigurationItem(item, userData.user),
  );
  return (
    <SubNav title="Knowledge Center">
      {parsedKnowledgeCenterSubNavConfig.map(item => {
        if (item.show === false) return null;
        return (
          <SubNavItem
            key={item.label}
            path={item.path}
            activeUrl={item.activeUrl}
            label={item.label}
            exact={item.exact}
          />
        );
      })}
    </SubNav>
  );
};
