import clsx from "clsx";
import { PropTypes } from "prop-types";

export const SubNav = ({ title, children, className }) => {
  return (
    <nav className={clsx("SubNav", className)}>
      <div className="SubNav__header">{title}</div>
      <ul className="SubNav__list">{children}</ul>
    </nav>
  );
};

SubNav.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
};
