import { useSelector } from "react-redux";
import { userSelectors } from "../../redux/user/userSelectors";
import { useEffect, useState } from "react";
import UnverifiedForm from "./UnverifiedForm";
import { userPlans } from "../../config/appConfig";
import Button from "@demystdata/ui/button";

export const UnverifiedDrawer = () => {
  const [response, setResponse] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const userPlan = useSelector(userSelectors.getUserPlan);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (response !== "") {
      setTimeout(() => {
        setResponse("");
      }, 2000);
    }
  }, [response]);

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  return (
    userPlan === userPlans.UNVERIFIED && (
      <>
        <UnverifiedForm
          setResponse={setResponse}
          open={showModal}
          onBackdropClick={() => setShowModal(false)}
        />
        <section className={`UnverifiedDrawer__container ${isMounted && "mounted"}`}>
          <p className="UnverifiedDrawer__title">
            {`Your organization is `}
            <span>Unverified</span>
            {` and will only return mocked data.`}
          </p>
          <section className="UnverifiedDrawer__bottom">
            <p className="UnverifiedDrawer__paragraph">
              Contact sales to verify your organization. Once completed, you will have full access
              to the datasets in the Demyst platform.
            </p>
            <Button onClick={() => setShowModal(true)}>Contact Sales</Button>
          </section>
        </section>
      </>
    )
  );
};
