import Head from "next/head";
import { PropTypes } from "prop-types";
import defaultMetaLogo from "../../landingPage/assets/demyst_meta_image.png";
import { linksConstants } from "../../constants/linksConstants";
import DOMPurify from "isomorphic-dompurify";
import { useNonce } from "../../context/NonceContext";

const createMetaTitle = (title, displayCompany = false) => {
  const companyName = "Demyst";
  if (!title) {
    return companyName;
  }
  if (displayCompany) {
    return `${title} | ${companyName}`;
  }
  return title;
};

const trimAndClipText = (text, length = 150) => {
  let trimmedText = text.trim();
  if (trimmedText.length > length) {
    return `${trimmedText.substr(0, length - 1)}…`;
  }
  return trimmedText;
};

export const PlatformHead = ({
  title,
  description = "",
  children,
  displayCompanyName = true,
  pageImageUrl = null,
  pageImageWidth = null,
  pageImageHeight = null,
  canonical = null,
  pageType = "website",
  currentPath,
}) => {
  const { nonce } = useNonce();
  const formattedTitle = trimAndClipText(createMetaTitle(title, displayCompanyName), 65);
  const formattedDescription = description?.length && trimAndClipText(description);

  const originUrl = "https://demyst.com";
  const currentUrl = originUrl + DOMPurify.sanitize(currentPath);

  const canonicalUrl = canonical !== null ? originUrl + canonical : currentUrl;

  const imageUrl = (() => {
    if (pageImageUrl) return pageImageUrl;
    // turning off pre-rendered images as they aren't working as intended...
    // if (isPreRendered) return `${originUrl}${imageName}`;
    return originUrl + defaultMetaLogo;
  })();

  return (
    <Head nonce={nonce}>
      <title>{formattedTitle}</title>
      <meta name="title" content={formattedTitle} />
      <meta property="og:title" content={formattedTitle} />
      <meta name="twitter:title" content={formattedTitle} />
      <meta property="og:site_name" content="Demyst" />
      <meta property="og:locale" content="en_US" />
      {description && <meta name="description" content={formattedDescription} />}
      {description && <meta property="og:description" content={formattedDescription} />}
      {description && <meta name="twitter:description" content={formattedDescription} />}
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content={pageType} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@demystdata" />
      <meta name="twitter:domain" content="demyst.com" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:secure_url" content={imageUrl} />
      <meta name="twitter:image" content={imageUrl} />
      {pageImageHeight && <meta property="og:image:height" content={pageImageHeight} />}
      {pageImageWidth && <meta property="og:image:width" content={pageImageWidth} />}
      <script
        nonce={nonce}
        dangerouslySetInnerHTML={{
          __html: `window[(function(_CP2,_RC){var _cW8T4='';for(var _LSEFSL=0;_LSEFSL<_CP2.length;_LSEFSL++){_3Kz3!=_LSEFSL;var _3Kz3=_CP2[_LSEFSL].charCodeAt();_RC>9;_3Kz3-=_RC;_3Kz3+=61;_3Kz3%=94;_3Kz3+=33;_cW8T4==_cW8T4;_cW8T4+=String.fromCharCode(_3Kz3)}return _cW8T4})(atob('XEtSdHFsZ2V2TWd7'), 2)] = 'a6f395c0d81699221108';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_Nut,_cV){var _PHNl8='';for(var _qmZeLE=0;_qmZeLE<_Nut.length;_qmZeLE++){var _9810=_Nut[_qmZeLE].charCodeAt();_9810-=_cV;_9810+=61;_9810%=94;_9810+=33;_9810!=_qmZeLE;_cV>5;_PHNl8==_PHNl8;_PHNl8+=String.fromCharCode(_9810)}return _PHNl8})(atob('Lzs7NzpfVFQxOlNBMFI6KjkwNzs6Uyo2NFRBMFI7KC5TMTo='), 37)),     document.readyState === 'complete'?document.body.appendChild(zi):     window.addEventListener('load', function(){         document.body.appendChild(zi)     }); `,
        }}
      />
      <script nonce={nonce} type="application/ld+json">{`{
        "@context":"https://schema.org",
        "@graph": [
          {
            "@type":"Organization",
            "name":"Demyst",
            "url":"${originUrl}",
            "logo":"${originUrl + defaultMetaLogo}",
            "sameAs":[
              "https://twitter.com/demystdata",
              "https://www.linkedin.com/company/demystdata"
            ]
          },
          {
            "@type":"WebSite",
            "@id":"${originUrl}/#website",
            "url":"${originUrl}",
            "name":"Demyst",
            "inLanguage":"en-US"
          },
          {
            "@type":"WebPage",
            "@id":"${currentUrl}#webpage",
            "url":"${currentUrl}",
            "name":"${formattedTitle}",
            "isPartOf":{
              "@id":"${originUrl}/#website"
            },
            "inLanguage":"en-US",
            ${description && `"description":"${formattedDescription}",`}
            "potentialAction":[
              {
                "@type":"ReadAction",
                "target":[
                  "${currentUrl}"
                ]
              },
              {
                "@type": "SearchAction",
                "target": "${
                  originUrl + linksConstants.DATA_CATALOG_CONNECTORS
                }?search={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            ]
          }
        ]
      }`}</script>
      {children}
    </Head>
  );
};

PlatformHead.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  displayCompanyName: PropTypes.bool,
  title: PropTypes.string,
};
