import { useEffect, useLayoutEffect, useState } from "react";
import { linksConstants } from "../../../constants/linksConstants";
import { useCasesActions, useCasesThunks } from "../../../redux/useCases/singleUseCaseSlice";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors } from "../../../redux/user/userSelectors";
import { Loader } from "../../components/Loader/Loader";
import { PlatformHead } from "../../layout/PlatformHead";
import { MessageBox } from "../../components/MessageBox/MessageBox";
import { LinkButton } from "../../components/buttons/LinkButton";
import { singleUseCaseSelectors } from "../../../redux/useCases/singleUseCaseSelectors";
import { toArray } from "../../../utils/helpers";
import { UseCasePageHeader } from "./components/UseCasePageHeader";
import { ChannelSwitcher } from "./components/ChannelSwitcher";
import { SingleUseCaseDateRangePicker } from "./components/SingleUseCaseDateRangePicker";
import { userPlans } from "../../../config/appConfig";
import Tabs from "@demystdata/ui/tabs";
import TabsItem from "@demystdata/ui/tabs_item";
import Link from "next/link";
import { userActions } from "../../../redux/user/userSlice";
import { useRouter } from "next/router";
import {
  formatAverageResponseTimeData,
  formatErrorsData,
  formatTransactionsData,
  useCaseQueryTypesConstants,
} from "../../../redux/useCases/helpers";
import { operationalDashboardApi } from "../../../api/operationalDashboardApi";
import Papa from "papaparse";
import { createCSVBlob, getBlobUrl } from "../../../utils/csv";
import { FiDownload } from "react-icons/fi";
import { notify } from "../../utils/notify";
import { sendGaEvent } from "../../../config/userTracking";
import { ChangeVisibility } from "./components/Overview/ChangeVisibilty";

export const possibleUseCaseItemViews = [
  {
    label: "Overview",
    name: "overview",
    link: linksConstants.USE_CASE_OVERVIEW,
  },
  {
    label: "Run Transaction",
    name: "tests",
    link: linksConstants.USE_CASE_TESTS,
    hidden: function ({ activeUseCase }) {
      return activeUseCase?.channels[0].single_lookupable === false;
    },
  },
  {
    label: "Batches",
    name: "batches",
    link: linksConstants.USE_CASE_BATCHES,
  },
  {
    label: "Reports",
    name: "reports",
    link: linksConstants.USE_CASE_REPORTS,
  },
  {
    label: "Alerts",
    name: "alerts",
    link: linksConstants.USE_CASE_ALERTS,
  },
  {
    label: "Error Logs",
    name: "errors",
    link: linksConstants.USE_CASE_ERRORS,
  },
  {
    label: "Settings",
    name: "settings",
    link: linksConstants.USE_CASE_SETTINGS,
  },
];

export const UseCasePage = ({ children }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [currentView, setCurrentView] = useState("");
  const [csvURL, setCsvUrl] = useState("");
  const userPlan = useSelector(userSelectors.getUserPlan);
  const isUserUnverified = userPlan === userPlans.UNVERIFIED;
  const { useCaseId } = router.query;

  const activeReport = useSelector(singleUseCaseSelectors.getActiveReport);
  const activeUseCase = useSelector(singleUseCaseSelectors.getActiveUseCase);
  const activeChannelsIds = useSelector(singleUseCaseSelectors.getActiveChannelsIds);
  const useCasesPagesDateRange = useSelector(singleUseCaseSelectors.getUseCasesPagesDateRange);

  const activeUseCaseFetchStatus = useSelector(singleUseCaseSelectors.getActiveUseCaseFetchStatus);
  const activeUseCaseError = useSelector(singleUseCaseSelectors.getActiveUseCaseError);

  const loading = activeUseCaseFetchStatus === "PENDING" || activeUseCaseFetchStatus === "IDLE";

  const getName = viewName => {
    const item = possibleUseCaseItemViews.find(item => item.name === viewName);
    return item?.label || "Demyst | Use Case";
  };

  useEffect(() => {
    dispatch(
      userActions.setLastLoggedOutPage({
        lastLoggedOutPage: window.location.pathname,
      }),
    );

    const scrollableWindow = document.getElementsByClassName("UseCasePage__contentWrapper");
    if (
      scrollableWindow !== null &&
      typeof scrollableWindow !== "undefined" &&
      scrollableWindow.length !== 0
    )
      scrollableWindow[0].scrollTop = 0;
    const view = window.location.pathname.split("/").reverse()[0];
    setCurrentView(view);
  }, [router.pathname]);

  useLayoutEffect(() => {
    dispatch(useCasesActions.setActiveUseCaseId({ useCaseId: useCaseId }));
  }, [dispatch, useCaseId]);

  useLayoutEffect(() => {
    const fetch = async () => {
      await dispatch(useCasesThunks.fetchSingleUseCase({ useCaseId: useCaseId }));
    };
    if (useCaseId && !activeUseCase) {
      fetch();
    }

    if (activeUseCase) {
      if (router.query["channel_id"]) {
        dispatch(
          useCasesActions.setActiveChannelsIds({
            channelsIds: toArray(router.query["channel_id"]),
          }),
        );
      } else {
        // if not, dispatch action with empty payload - this will set use case's default channel
        // as active. For more info look into `setActiveChannelsIds` reducer code.
        dispatch(useCasesActions.setActiveChannelsIds({}));
      }
      // the same logic as for channel id here
      if (router.query["start_date"] || router.query["end_date"]) {
        dispatch(
          useCasesActions.setDateRange({
            startDate: router.query["start_date"],
            endDate: router.query["end_date"],
          }),
        );
      }
    }
  }, [dispatch, useCaseId, activeUseCase]);

  useEffect(() => {
    if (activeChannelsIds.length >= 1) {
      router.query["channel_id"] = activeChannelsIds;
      router.query["start_date"] = useCasesPagesDateRange[0];
      router.query["end_date"] = useCasesPagesDateRange[1];
      router.replace({
        query: router.query,
      });
    }
  }, [activeChannelsIds, useCasesPagesDateRange]);

  useEffect(() => {
    const fetchTransactionData = async () => {
      if (activeReport.type === "CUMULATIVE" && getName(currentView) === "Reports") {
        // get transactions
        const { data: transactionsRawData } = await operationalDashboardApi.getQueryData({
          startDate: useCasesPagesDateRange[0],
          endDate: useCasesPagesDateRange[1],
          queryType: useCaseQueryTypesConstants.transactions,
          channelsIds: activeChannelsIds,
        });
        const { transactionsPerDayData } = formatTransactionsData({
          transactionsRawData,
          activeChannelsIds: activeChannelsIds,
          startDate: useCasesPagesDateRange[0],
          endDate: useCasesPagesDateRange[1],
        });
        // get response times
        const { data: averageResponseTimeRawData } = await operationalDashboardApi.getQueryData({
          startDate: useCasesPagesDateRange[0],
          endDate: useCasesPagesDateRange[1],
          queryType: useCaseQueryTypesConstants.averageResponseTime,
          channelsIds: activeChannelsIds,
        });
        const { averageResponseTimePerDayData } = formatAverageResponseTimeData({
          averageResponseTimeRawData,
          activeChannelsIds: activeChannelsIds,
          startDate: useCasesPagesDateRange[0],
          endDate: useCasesPagesDateRange[1],
        });
        const { data: errorsRawData } = await operationalDashboardApi.getQueryData({
          startDate: useCasesPagesDateRange[0],
          endDate: useCasesPagesDateRange[1],
          queryType: useCaseQueryTypesConstants.errors,
          channelsIds: activeChannelsIds,
        });
        const { errorsPerDayData } = formatErrorsData({
          errorsRawData,
          activeChannelsIds: activeChannelsIds,
          startDate: useCasesPagesDateRange[0],
          endDate: useCasesPagesDateRange[1],
        });
        const csvData = transactionsPerDayData.map(transaction => ({
          ...transaction,
          ...transaction.channelsData[activeChannelsIds[0]],
          ...errorsPerDayData.find(error => error.date === transaction.date).channelsData[
            activeChannelsIds[0]
          ],
          ...averageResponseTimePerDayData.find(response => response.date === transaction.date)
            .channelsData[activeChannelsIds[0]],
        }));
        const csv = Papa.unparse(csvData, {
          columns: [
            "date",
            "channelId",
            "sampleTransactionCount",
            "liveTransactionCount",
            "hitCount",
            "mode",
            "errorCount",
            "averageResponseTime",
            "p99ResponseTime",
            "p95ResponseTime",
          ],
        });
        const csvFile = createCSVBlob(csv);
        setCsvUrl(getBlobUrl(csvFile));
      }
    };
    fetchTransactionData();
  }, [router.query, activeChannelsIds, useCasesPagesDateRange]);

  const onDownloadClick = () => {
    notify.success("Your download has started!");

    sendGaEvent({
      action: "CSV Download",
      category: "Platform - KPI Event",
      label: `Download CSV - Channel ${activeChannelsIds[0]} (${useCasesPagesDateRange[0]} to ${useCasesPagesDateRange[1]})`,
    });
  };

  return (
    <div className="UseCasePage">
      {loading ? (
        <div className="UseCasePage__loading">
          <Loader />
        </div>
      ) : activeUseCaseError ? (
        <div className="UseCasePage__error">
          <PlatformHead title="An Error Occurred" />
          <MessageBox>{activeUseCaseError.message}</MessageBox>
          <LinkButton href={linksConstants.ALL_USE_CASES}>Back to Dashboard</LinkButton>
        </div>
      ) : (
        !activeUseCase?.channels && (
          <div className="UseCasePage__noData">
            <PlatformHead title="Use Case Not Found" />
            <h3>Use case with this ID does not exist or has no channels assigned to it.</h3>
            <LinkButton href={linksConstants.ALL_USE_CASES}>Back to Dashboard</LinkButton>
          </div>
        )
      )}
      {!loading && activeUseCase && (
        <main className="UseCasePage__contentWrapper">
          <PlatformHead title={`${getName(currentView)} | ${activeUseCase?.name}`} />
          <UseCasePageHeader activeUseCase={activeUseCase} activeChannelsIds={activeChannelsIds}>
            <section className="UseCasePage__header">
              <ChangeVisibility useCase={activeUseCase} />
              {activeReport.type === "CUMULATIVE" && getName(currentView) === "Reports" && (
                <LinkButton
                  isExternal
                  href={csvURL}
                  download
                  variant={"whiteWithShadow"}
                  onClick={onDownloadClick}
                >
                  <FiDownload />
                  Export
                </LinkButton>
              )}
              {activeUseCase?.channels.length > 1 && <ChannelSwitcher />}
              {(getName(currentView) === "Error Logs" ||
                getName(currentView) === "Reports" ||
                getName(currentView) === "Alerts") && <SingleUseCaseDateRangePicker />}
            </section>
          </UseCasePageHeader>
          <section className="UseCasePage__tabs">
            <Tabs variant="line">
              {possibleUseCaseItemViews
                .filter(item => !item.hidden || !item.hidden({ activeUseCase }))
                .map(view => (
                  <Link
                    key={view.name}
                    href={`/app/use-cases/${useCaseId}/${view.name}`}
                    legacyBehavior
                  >
                    <TabsItem
                      badgeColor="blue"
                      className={`UseCasePage__tabs__item ${
                        currentView === view.name ? "active" : ""
                      }`}
                    >
                      {view.label}
                    </TabsItem>
                  </Link>
                ))}
            </Tabs>
          </section>
          {isUserUnverified && (
            <div className="UseCasePage__unverified__container">
              <p className="UseCasePage__unverified">
                {`Your organization is `}
                <span>Unverified</span>
                {` therefore this Data API can only return sample data.`}
              </p>
            </div>
          )}
          {children}
        </main>
      )}
    </div>
  );
};
