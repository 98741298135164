import { useDispatch } from "react-redux";
import { userActions } from "../../redux/user/userSlice";
import { userPlans } from "../../config/appConfig";

export const FreeUserBar = ({ userPlan }) => {
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(userActions.setContactUsModalState(true));
  };

  return (
    <>
      {userPlan === userPlans.LEAD && (
        <section className="FreeUserBar">
          <p>
            You are using a free version of the Demyst Platform. To get started integrating your
            first Data API, <button onClick={openModal}>click here</button>.
          </p>
        </section>
      )}
    </>
  );
};
