import { PropTypes } from "prop-types";
import clsx from "clsx";
import { isNavItemActive } from "../Navigation";
import { toArray } from "../../../../utils/helpers";
import { AnimatePresence, motion } from "framer-motion";
import { linksConstants } from "../../../../constants/linksConstants";
import Link from "next/link";
import { useRouter } from "next/router";

export const SubNavItem = ({
  activeUrl,
  path,
  label,
  exact = false,
  children,
  className,
  onClick,
}) => {
  const isUseCasesPath = path.indexOf(linksConstants.USE_CASES) !== -1;
  const isAllUseCasesPath = path.indexOf(linksConstants.ALL_USE_CASES) !== -1;
  const router = useRouter();
  const isActive = isNavItemActive({
    urls: [path, ...toArray(activeUrl)],
    pathname: router.asPath,
    exact,
  });

  return (
    <li className={clsx("SubNavItem", className)}>
      {isUseCasesPath && !isAllUseCasesPath ? (
        <div className="SubNavItem__link">{label}</div>
      ) : (
        <Link
          href={path}
          className={clsx("SubNavItem__link", isActive && "SubNavItem__link--active")}
          onClick={onClick}
        >
          {label}
        </Link>
      )}

      {children && (
        <AnimatePresence initial={false}>
          {isActive && (
            <motion.ul
              className="SubNavItem__subItemsList"
              key={"subItems"}
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.3, type: "tween" }}
            >
              {children}
            </motion.ul>
          )}
        </AnimatePresence>
      )}
    </li>
  );
};

SubNavItem.propTypes = {
  activeUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  path: PropTypes.string,
};
