import { format, isSameDay } from "date-fns";
import { createDateFromString } from "./date";

export const getFormattedDateRange = (startDate, endDate, dateFormat = "MMM dd, yyyy") => {
  if (!startDate || !endDate) {
    return;
  }

  let startDateObj, endDateObj;
  if (startDate instanceof Date) {
    startDateObj = startDate;
  } else {
    startDateObj = createDateFromString(startDate);
  }
  if (endDate instanceof Date) {
    endDateObj = endDate;
  } else {
    endDateObj = createDateFromString(endDate);
  }

  if (isSameDay(startDateObj, endDateObj)) {
    return format(startDateObj, dateFormat);
  } else {
    return `${format(startDateObj, dateFormat)} - ${format(endDateObj, dateFormat)}`;
  }
};

export const adjustTime = str => {
  let arr = str.split("-");
  let month = arr[1];
  let last = arr[arr.length - 1];

  const months = ["01", "03", "05", "07", "08", "10", "12"];

  const adjustMonth = str => {
    let arr = str.split("-");
    arr[1] = parseInt(arr[1]) + 1;
    arr[arr.length - 1] = "01";
    if (arr[1] < 10) {
      arr[1] = "0" + arr[1];
    }
    return arr.join("-");
  };

  const adjustYear = str => {
    let arr = str.split("-");
    arr[0] = parseInt(arr[0]) + 1;
    arr[1] = "01";
    arr[2] = "01";
    return arr.join("-");
  };

  const adjustDay = () => {
    if (parseInt(arr[arr.length - 1]) < 9) {
      arr[arr.length - 1] = "0" + (parseInt(arr[arr.length - 1]) + 1);
    } else {
      arr[arr.length - 1] = (parseInt(arr[arr.length - 1]) + 1).toString();
    }
    return arr.join("-");
  };

  if (parseInt(last) === 31 && parseInt(month) === 12) {
    return adjustYear(str);
  }

  if (parseInt(last) === 31) {
    return adjustMonth(str);
  }

  if (months.indexOf(month) === -1 && parseInt(last) === 30) {
    return adjustMonth(str);
  }

  if (months.indexOf(month) !== -1 && parseInt(last) === 31) {
    return adjustMonth(str);
  }

  adjustDay();

  return arr.join("-");
};
