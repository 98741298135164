import { PropTypes } from "prop-types";
import { FiLock } from "react-icons/fi";
import Nav from "@demystdata/ui/nav";
import NavItem from "@demystdata/ui/nav_item";
import { linksConstants } from "../../../constants/linksConstants";
import { possibleUseCaseItemViews } from "../../views/UseCases/UseCasePage";
import Link from "next/link";
import { useRouter } from "next/router";

export const MainNav = ({ mainNavConfig, closeNavigation }) => {
  const router = useRouter();
  const isDashboardItem = path => {
    const possibleUseCasePaths = possibleUseCaseItemViews.map(item => item.name);

    return (
      path === linksConstants.USE_CASES &&
      (router.asPath.startsWith(linksConstants.USE_CASE_PAGE_ROOT) ||
        possibleUseCasePaths.some(casePath => router.asPath.startsWith(casePath)))
    );
  };

  const isCatalogItem = path => {
    return (
      path === linksConstants.DATA_CATALOG &&
      (router.asPath?.startsWith(linksConstants.DATA_CATALOG) ||
        router.asPath?.startsWith(linksConstants.RECIPE_ROOT) ||
        router.asPath?.startsWith(linksConstants.CONNECTOR_ROOT))
    );
  };

  const isProjectsItem = path => {
    return (
      path === linksConstants.PROJECTS_ALL &&
      (router.asPath?.startsWith(linksConstants.PROJECTS_ALL) ||
        router.asPath?.startsWith(linksConstants.PROJECTS_SANDBOX) ||
        router.asPath?.startsWith(linksConstants.PROJECTS_DEPLOYED))
    );
  };

  const isInternalDataItem = path => {
    return (
      path === linksConstants.INTERNAL_DATA_CONNECTORS &&
      router.asPath?.startsWith("/app/internal-data/")
    );
  };

  const isProjectItem = path => {
    return path === linksConstants.PROJECTS_ALL && router.asPath?.startsWith("/app/projects/");
  };

  return (
    <div className="MainNav">
      <Nav>
        {mainNavConfig
          .filter(item => item.show)
          .map(navItem => {
            return (
              <>
                {navItem.label === "Admin" ? <hr /> : <></>}
                {navItem.enabled ? (
                  navItem.target_blank ? (
                    <NavItem
                      key={navItem.label}
                      href={navItem.path}
                      leftIconSrc={navItem.logo}
                      className="MainNav__list__item__link"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {navItem.label}
                    </NavItem>
                  ) : (
                    <NavItem
                      key={navItem.label}
                      as={Link}
                      href={navItem.path}
                      leftIconSrc={navItem.logo}
                      className={`MainNav__list__item__link ${
                        isProjectsItem(navItem.path) ||
                        isDashboardItem(navItem.path) ||
                        isCatalogItem(navItem.path) ||
                        isInternalDataItem(navItem.path) ||
                        isProjectItem(navItem.path) ||
                        navItem.path === router.asPath
                          ? "active"
                          : ""
                      }`}
                      onClick={closeNavigation}
                    >
                      {navItem.label}
                    </NavItem>
                  )
                ) : (
                  <NavItem
                    key={navItem.label}
                    leftIconSrc={<FiLock />}
                    className="MainNav__list__item__link MainNav__list__item__link--disabled"
                  >
                    {navItem.label}
                  </NavItem>
                )}
              </>
            );
          })}
      </Nav>
    </div>
  );
};

MainNav.propTypes = {
  mainNavConfig: PropTypes.array,
};
