import { useState } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { userSelectors } from "../../redux/user/userSelectors";
import { TextArea } from "../components/formComponents";
import { RadioButton } from "../components/formComponents/RadioButton";
import { userActions } from "../../redux/user/userSlice";
import { Loader } from "../components/Loader/Loader";
import { MessageBox } from "../components/MessageBox/MessageBox";
import { Button } from "../components/buttons/Button";
import { serializeError } from "../../utils/serializeError";
import { operationalDashboardApi } from "../../api/operationalDashboardApi";
import { userApi } from "../../api/userApi";
import { formAttribution } from "../../utils/formAttribution";

export const ContactModal = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userSelectors.getUserData);
  const [radioValue, setRadioValue] = useState(0);
  const [sent, setSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async event => {
    event.preventDefault();

    setError(null);
    setIsLoading(true);

    try {
      const formData = new FormData(event.target);
      const radio = formData.get("solution_category");
      const text = formData.get("comments");

      const dataToSend = {
        type: "Platform Qualification Request",
        formValues: formAttribution("Platform Qualification Request"),
      };
      dataToSend.formValues.email = userData.user.email;
      dataToSend.formValues.full_name = userData.user.name;
      dataToSend.formValues.company = userData.organization.name;
      dataToSend.formValues.category = radio;
      dataToSend.formValues.message = text;

      await userApi.sendFrontForm(dataToSend);
    } catch (e) {
      console.log("Failed to send analytics data");
    }

    try {
      const formData = new FormData(event.target);
      const radio = formData.get("solution_category");
      const text = formData.get("comments");

      const requestData = {
        email: userData.user.email,
        message: text,
        name: userData.user.name,
        company: userData.organization.name,
        category: radio,

        ...formAttribution("Platform Qualification Request"),
      };

      await operationalDashboardApi.sendSupportEmail(requestData);

      setSent(true);
    } catch (e) {
      setError(serializeError(e));
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    if (!isLoading) {
      dispatch(userActions.setContactUsModalState(false));
    }
  };

  return (
    <section className="ContactModal">
      <div
        onClick={closeModal}
        className="ContactModal__background"
        onKeyPress={closeModal}
        role="button"
        tabIndex="0"
      />
      <div className="ContactModal__modal">
        {!sent && (
          <>
            <h3 className="ContactModal__modal__header">Let's get into production!</h3>
            <p className="ContactModal__modal__paragraph">
              We are ready to help accelerate your external data driven application. To get started
              help us understand a little more about your use case:
            </p>
            {isLoading && (
              <div className="ContactModal__loading">
                <Loader />
              </div>
            )}
            <form onSubmit={handleSubmit} className="ContactModal__form">
              <section className="ContactModal__form__radio">
                <p>Category</p>
                <section className="ContactModal__form__radio__container">
                  <RadioButton
                    id="crime"
                    name="solution_category"
                    label="FinCrime & Fraud"
                    value="FinCrime & Fraud"
                    checked={radioValue === 0}
                    onChange={() => setRadioValue(0)}
                  />
                  <RadioButton
                    id="marketing"
                    name="solution_category"
                    label="Marketing & Sales"
                    value="Marketing & Sales"
                    checked={radioValue === 1}
                    onChange={() => setRadioValue(1)}
                  />
                  <RadioButton
                    id="credit"
                    name="solution_category"
                    label="Credit & Underwriting"
                    value="Credit & Underwriting"
                    checked={radioValue === 2}
                    onChange={() => setRadioValue(2)}
                  />
                  <RadioButton
                    id="data"
                    name="solution_category"
                    label="Data & Analytics"
                    value="Data & Analytics"
                    checked={radioValue === 3}
                    onChange={() => setRadioValue(3)}
                  />
                  <RadioButton
                    id="other"
                    name="solution_category"
                    label="Other"
                    value="Other"
                    checked={radioValue === 4}
                    onChange={() => setRadioValue(4)}
                  />
                </section>
              </section>
              <TextArea name="comments" label="Additional comments" />
              {error && (
                <div className="ContactModal__form__error">
                  <MessageBox>{error.message}</MessageBox>
                </div>
              )}
              <div className="ContactModal__form__actions">
                <Button type="submit" disabled={isLoading}>
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
        {sent && (
          <>
            <h3 className="ContactModal__modal__header">Next steps...</h3>
            <p className="ContactModal__modal__paragraph">
              Thank you! We will be reviewing your submission and getting back to you shortly. If
              you have any questions feel free to email us at{" "}
              <a href="mailto:support@demystdata.com">support@demystdata.com</a>.
            </p>
            <Button onClick={closeModal}>Close this window</Button>
          </>
        )}
      </div>
    </section>
  );
};
