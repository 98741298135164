import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

export const notify = {
  default: function (content, options) {
    return toast(content, {
      autoClose: 3000,
      closeOnClick: false,
      draggable: false,
      ...options,
    });
  },
  success: function (content, options) {
    return toast.success(content, {
      autoClose: 3000,
      closeOnClick: false,
      draggable: false,
      className: "UseCaseBatchesPage__infobox UseCaseBatchesPage__infobox--success",
      ...options,
    });
  },
  successBatch: function (content, options) {
    return toast.success(content, {
      closeOnClick: true,
      draggable: false,
      ...options,
    });
  },
  error: function (content, options) {
    return toast.error(content, {
      autoClose: 3000,
      closeOnClick: false,
      draggable: false,
      ...options,
    });
  },
  stateful: function (content, options) {
    const toastId = toast(content ?? "Saving...", options);
    return {
      id: toastId,
      updateSuccess: function (content, options) {
        toast.update(String(toastId), {
          render: content,
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
          closeOnClick: false,
          draggable: false,
          ...options,
        });
      },
      updateError: function (content, options) {
        toast.update(String(toastId), {
          render: content,
          type: toast.TYPE.ERROR,
          autoClose: 3000,
          closeOnClick: false,
          draggable: false,
          ...options,
        });
      },
    };
  },
};
