import styles from "./UnverifiedForm.module.less";
import { Modal } from "../components/Modal/Modal";
import Button from "@demystdata/ui/button";
import { useState } from "react";
import { userApi } from "../../api/userApi";
import { Loader } from "../components/Loader/Loader";
import { FiInfo, FiX } from "react-icons/fi";
import { Select } from "../components/formComponents/Select";
import { useSelector } from "react-redux";
import { userSelectors } from "../../redux/user/userSelectors";

const volumeOptions = [
  "Unknown (default)",
  "0 - 10,000 per month",
  "10,001 - 20,000 per month",
  "20,001 - 30,000 per month",
  "30,001 - 40,000 per month",
  "40,001 - 50,000 per month",
  "50,001+ per month",
];

const UnverifiedForm = ({ open, onBackdropClick, setResponse }) => {
  const [volume, setVolume] = useState(volumeOptions[0]);
  const [useCaseDescription, setUseCaseDescription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    user: { email },
  } = useSelector(userSelectors.getUserData);

  const resetForm = () => {
    setUseCaseDescription(null);
    setLoading(false);
    setError(null);
  };

  const setVolumeOption = target => {
    setVolume(target);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    resetForm();
    setLoading(true);
    try {
      const dataToSend = {
        type: "unverified_user_contact_sales",
        formValues: {
          use_case_description: useCaseDescription,
          contact_email: email,
          volume,
        },
      };
      await userApi.sendFrontForm(dataToSend);
      onBackdropClick && onBackdropClick();
      setResponse("Message sent. We will be in touch shortly.");
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onBackdropClick={() => {
        resetForm();
        onBackdropClick();
      }}
    >
      <div className={styles.modal}>
        {loading && (
          <div className={styles.loading}>
            <Loader loading={true} />
          </div>
        )}
        <button
          onClick={() => {
            resetForm();
            onBackdropClick();
          }}
          className={styles.close}
        >
          <FiX />
        </button>
        <form className={styles.form} onSubmit={handleSubmit}>
          <p className={styles.title}>Verify your organization</p>
          <p className={styles.description}>
            Contact sales now and someone on the Demyst team will be in touch to verify your
            organization and your data use case.
          </p>
          <div className={styles.field}>
            <p className={styles.inputTitle}>
              What is your business use case for Demyst Data APIs?
            </p>
            <textarea
              className={styles.textArea}
              required
              value={useCaseDescription}
              onChange={({ target: { value } }) => setUseCaseDescription(value)}
            />
            <section className={styles.infoBox}>
              <FiInfo />
              <p className={styles.infoDescription}>
                Your business use case is the manner in which you will leverage data to derive
                insights to answer tangible questions for decision-making. Valid use cases are
                required to access certain datasets.
              </p>
            </section>
          </div>
          <div className={styles.field}>
            <p className={styles.inputTitle}>
              What is your estimated monthly API transaction volume?
            </p>
            <Select
              name="estimated_volume"
              value={volume.label}
              onChange={e => setVolumeOption(e.target.value)}
            >
              {volumeOptions.map(option => (
                <option key={option}>{option}</option>
              ))}
            </Select>
            <section className={styles.infoBox}>
              <FiInfo />
              <p className={styles.infoDescription}>
                We offer significant discounts on data when run at greater volumes. Demyst returns
                one record per transaction in Data APIs.
              </p>
            </section>
          </div>
          <div className={styles.error}>{error}</div>
          <Button type="submit" className={styles.submitButton} variant="primary">
            Contact Sales
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default UnverifiedForm;
