import { userSelectors } from "../../redux/user/userSelectors";
import { useSelector } from "react-redux";

export const useShowPricing = () => {
  const isUserLoggedIn = useSelector(userSelectors.isUserLoggedIn);
  const hasNewOrOneTrueCreditsEnabled = useSelector(userSelectors.hasNewOrOneTrueCreditsEnabled);

  if (!isUserLoggedIn) {
    return false;
  }

  return hasNewOrOneTrueCreditsEnabled;
};
