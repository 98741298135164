import { useDispatch, useSelector } from "react-redux";
import { singleUseCaseSelectors } from "../../../../redux/useCases/singleUseCaseSelectors";
import { useCasesActions } from "../../../../redux/useCases/singleUseCaseSlice";
import { DateRangePickerComponent } from "../../../components/DatePickers/DateRangePickerComponent";

export const SingleUseCaseDateRangePicker = () => {
  const dispatch = useDispatch();
  const dateRange = useSelector(singleUseCaseSelectors.getUseCasesPagesDateRange);

  const onDateRangeChange = dateRange => {
    dispatch(useCasesActions.setDateRange({ startDate: dateRange[0], endDate: dateRange[1] }));
  };

  return (
    <DateRangePickerComponent
      startDate={dateRange[0]}
      endDate={dateRange[1]}
      onApply={onDateRangeChange}
    />
  );
};
