import { PropTypes } from "prop-types";
import { UserMenu } from "./UserMenu";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors } from "../../redux/user/userSelectors";
import { DemystLogoMobile } from "../assets/svg/DemystLogoMobile";
import { useViewport } from "../utils/useViewport";
import { useShowPricing } from "../../utils/hooks/useShowPricing";
import { useInterval } from "../../utils/hooks/useInterval";
import { userThunks } from "../../redux/user/userSlice";
import { trimTrailingZeroes } from "../../utils/helpers";
import { Button } from "../components/buttons/Button";
import { UnverifiedButton } from "./UnverifiedButton";
import { linksConstants } from "../../constants/linksConstants";
import { FiExternalLink, FiSearch } from "react-icons/fi";
import { SearchBar } from "./SearchBar";
import Link from "next/link";
import { useMatchMedia } from "@wojtekmaj/react-hooks";

export const AppHeader = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userSelectors.getUserData);
  const isUserLoggedIn = useSelector(userSelectors.isUserLoggedIn);
  const showPricing = useShowPricing();
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const { mobile } = useViewport();
  const isSmallMobile = useMatchMedia("screen and (max-width: 465px)");

  useEffect(() => {
    if (
      isUserLoggedIn &&
      userData?.organization?.credits_or_caps === "one_credit_to_rule_them_all" &&
      userData?.organization?.credits_spent_this_month == null
    ) {
      dispatch(userThunks.updateUserCreditBalance());
    }
  }, [isUserLoggedIn, userData]);
  useInterval(() => dispatch(userThunks.updateUserCreditBalance()), 1800000);

  return (
    <header className="AppHeader">
      {mobile && (
        <div className="AppHeader__demystLogo">
          {isSmallMobile && isSearchVisible ? null : <DemystLogoMobile />}
        </div>
      )}
      <div className="AppHeader__dynamicContent">
        <div className="AppHeader__dynamicContent--mobile">
          <div
            className={`AppHeader__dynamicContent__searchBarWrapper ${
              isSearchVisible ? "open" : ""
            }`}
          >
            <SearchBar />
          </div>
          {/* {isUserLoggedIn && <UnverifiedButton isSearchVisible={isSearchVisible} origin="mobile" />} */}
          <button
            onClick={() => setIsSearchVisible(!isSearchVisible)}
            className="AppHeader__dynamicContent--mobile__buttonStyle"
          >
            <FiSearch />
          </button>
        </div>
        <div className="AppHeader__dynamicContent--desktop">
          <SearchBar />
        </div>
      </div>
      {isUserLoggedIn && (
        <>
          {showPricing && userData.organization.credits_or_caps === "new_credits" && (
            <div className="AppHeader__creditsInfoWrapper">
              <p className="AppHeader__creditsInfo AppHeader__creditsInfo--evaluation">
                <span className="AppHeader__creditsInfo__value AppHeader__creditsInfo__value--evaluation">
                  {trimTrailingZeroes(userData.organization.evaluation_credits)}
                </span>
                <span className="AppHeader__creditsInfo__label AppHeader__creditsInfo__label--long">
                  Evaluation Credits
                </span>
                <span className="AppHeader__creditsInfo__label AppHeader__creditsInfo__label--short">
                  Eval. Credits
                </span>
              </p>
              <p className="AppHeader__creditsInfo AppHeader__creditsInfo--production">
                <span className="AppHeader__creditsInfo__value AppHeader__creditsInfo__value--production">
                  {trimTrailingZeroes(userData.organization.production_credits)}
                </span>
                <span className="AppHeader__creditsInfo__label AppHeader__creditsInfo__label--long">
                  Production Credits
                </span>
                <span className="AppHeader__creditsInfo__label AppHeader__creditsInfo__label--short">
                  Prod. Credits
                </span>
              </p>
            </div>
          )}

          {showPricing &&
            userData.organization.credits_or_caps === "one_credit_to_rule_them_all" &&
            userData.organization.credits_spent_this_month != null && (
              <div className="AppHeader__creditsInfoWrapper">
                <p className="AppHeader__creditsInfo AppHeader__creditsInfo--production">
                  <span className="AppHeader__creditsInfo__value AppHeader__creditsInfo__value--production">
                    {trimTrailingZeroes(userData.organization.credits_spent_this_month)}
                  </span>
                  <span className="AppHeader__creditsInfo__label AppHeader__creditsInfo__label--long">
                    Credits Spent this Month
                  </span>
                  <span className="AppHeader__creditsInfo__label AppHeader__creditsInfo__label--short">
                    Credits Spent
                  </span>
                </p>
              </div>
            )}
          <UnverifiedButton origin="desktop" />
          <UserMenu />
        </>
      )}
      {!isUserLoggedIn && (
        <div className="AppHeader__publicHeaderWrapper">
          <Link href={linksConstants.HOME} className="AppHeader__publicHeader__link">
            Back To Website
            <FiExternalLink />
          </Link>
          <Link href={linksConstants.LOGIN} className="AppHeader__publicHeader__button">
            <Button variant="whiteWithShadow">Log In</Button>
          </Link>
        </div>
      )}
    </header>
  );
};

AppHeader.propTypes = {
  children: PropTypes.node,
};
