export const generatePath = (template = "", params = {}) => {
  return template
    .split("/")
    .map(s => {
      if (s.startsWith(":")) {
        return params[s.slice(1)];
      }
      return s;
    })
    .join("/");
};

export const matchPath = (pathname = "", { path = "", exact = true } = {}) => {
  if (exact) return pathname === path;
  else {
    if (pathname.startsWith(path)) return true;
    const splittedPath = path.split("/");
    const splittedPathname = pathname.split("/");
    let doesMatch = true;
    for (let i = 0; i < splittedPathname.length; i += 1) {
      if (splittedPath[i]?.startsWith(":")) {
        doesMatch = true;
      } else {
        doesMatch = splittedPath[i] === splittedPathname[i];
      }
      if (!doesMatch) break;
    }
    return doesMatch;
  }
};
