import { useEffect, useState } from "react";
import { linksConstants } from "../../constants/linksConstants";
import { useSelector } from "react-redux";
import { productsCatalogSelectors } from "../../redux/productsCatalog/productsCatalogSelectors";
import { FiSearch } from "react-icons/fi";
import { productsCatalogApi } from "../../api/productsCatalogApi";
import Axios from "axios";
import { useAxiosSource } from "../../utils/hooks/useAxiosSource";
import { AnimatePresence, motion } from "framer-motion";
import ClickAwayListener from "react-click-away-listener";
import { animatePresenceConfig } from "../../config/appConfig";
import { Loader } from "../components/Loader/Loader";
import { sendGaEvent } from "../../config/userTracking";
import { userSelectors } from "../../redux/user/userSelectors";
import { useDebounce } from "../../utils/hooks/useDebounce";

import { RegionLogo } from "../views/DataCatalog/components/RegionLogo";
import demystLogo from "../assets/datasourceLogos/demyst.png";
import Image from "next/image";
import { toNaturalSentence } from "../../utils/string";
import Input from "@demystdata/ui/input";
import Link from "next/link";
import { useRouter } from "next/router";
import DOMPurify from "dompurify";

const SearchBarList = ({
  searchList,
  type,
  hoverOverItem,
  searchInputValue,
  contentNumbers,
  desiredContent,
  setShowList,
  setSearchInputValue,
}) => {
  const router = useRouter();
  const userData = useSelector(userSelectors.getUserData);

  const previousNumberCount = contentNumbers
    .filter((item, itemIndex) => itemIndex < desiredContent)
    .reduce((acc, value) => acc + value, 0);

  const currentNumberCount = contentNumbers
    .filter((item, itemIndex) => itemIndex <= desiredContent)
    .reduce((acc, value) => acc + value, 0);

  const recipePath = str => {
    return str
      .split(" ")
      .map(item => {
        return item[0].toLowerCase() + item.slice(1, item.length);
      })
      .join("-");
  };

  const createPathname = ({ type, item }) => {
    const link = () => {
      const currentPage = router.asPath;
      const isDataCatalog = currentPage.indexOf(linksConstants.DATA_CATALOG) !== -1;
      if (isDataCatalog) {
        const view = currentPage.split("/").reverse()[0];
        switch (view) {
          case "featured":
            return linksConstants.DATA_CATALOG_CONNECTORS;
          case "recipes":
            return linksConstants.DATA_CATALOG_RECIPES;
          default:
            return linksConstants.DATA_CATALOG_CONNECTORS;
        }
      } else {
        return linksConstants.DATA_CATALOG_CONNECTORS;
      }
    };

    switch (type) {
      case "Connector":
        return `${linksConstants.CONNECTOR_ROOT}/${item.id}`;
      case "Attribute":
        return `${linksConstants.CONNECTOR_ROOT}/${item.table_provider.id}`;
      case "API Recipe":
        return `${linksConstants.RECIPE_ROOT}/${item.id}/${recipePath(item.name)}`;
      case "Data API":
        return `${linksConstants.USE_CASE_PAGE_ROOT}/${item.id}/overview`;
      default:
        return link();
    }
  };

  const createSearch = ({ type, item }) => {
    const parsedQs = {
      ...router.query,
      page: 1,
      search: "",
    };
    if (type === "Source") parsedQs.data_source = item.name;
    if (type === "Region") parsedQs.regions = [item.name];
    switch (type) {
      case "Connector":
        return;
      case "API Recipe":
        return {
          channel_id: item.channel_id,
        };
      default:
        return parsedQs;
    }
  };

  const linkAction = ({ type, item, setShowList, setSearchInputValue }) => {
    if (type === "Region" || type === "Source") {
      setSearchInputValue("");
    }
    setShowList(false);
    sendGaEvent(
      {
        action: `Autocomplete Partner "${item.name}"`,
        category: "Connector Search",
      },
      userData?.user?.email,
    );
  };

  return searchList
    .filter(
      (position, positionIndex) =>
        positionIndex >= previousNumberCount && positionIndex < currentNumberCount,
    )
    .map((item, itemIndex) => (
      <Link
        tabIndex="-1"
        className={`SearchBar__autocomplete__list__item ${itemIndex === 0 && "separator"} ${type
          .toLowerCase()
          .replace(" ", "_")}`}
        key={`${item.id}-${type}`}
        href={{
          pathname: createPathname({ type, item }),
          query: createSearch({ type, item }),
          state: { fromSearchbar: true, fetchType: type.toLowerCase() },
        }}
        onClick={() => linkAction({ type, item, setShowList, setSearchInputValue })}
        onMouseEnter={() => hoverOverItem(itemIndex + previousNumberCount)}
      >
        <div
          className={`SearchBar__autocomplete__list__images ${type
            .toLowerCase()
            .replace(" ", "_")}`}
        >
          {type === "API Recipe" &&
            item.table_providers
              ?.filter((provider, providerIndex) => providerIndex < 4)
              .map(provider =>
                provider.logo ? (
                  provider.logo.indexOf("blue_dot") === -1 ? (
                    <img
                      key={provider.name}
                      src={provider.logo}
                      alt={
                        provider.name.toLowerCase() === "undefined" ? "" : `${provider.name} logo`
                      }
                      loading="eager"
                    />
                  ) : (
                    <Image src={demystLogo} alt="Demyst logo" width="16" height="16" />
                  )
                ) : (
                  <Image src={demystLogo} alt="Demyst logo" width="16" height="16" />
                ),
              )}
          {(type === "Connector" || type === "Source") &&
            (item.logo ? (
              item.logo.indexOf("blue_dot") === -1 ? (
                <img
                  key={item.name}
                  src={item.logo}
                  alt={item.name.toLowerCase() === "undefined" ? "" : `${item.name} logo`}
                  loading="eager"
                />
              ) : (
                <Image src={demystLogo} alt="Demyst logo" width="20" height="20" />
              )
            ) : (
              <Image src={demystLogo} alt="Demyst logo" width="20" height="20" />
            ))}
          {type === "Attribute" && item.table_provider.logo && (
            <img
              key={item.table_provider.name}
              src={item.table_provider.logo}
              alt={
                item.table_provider.name.toLowerCase() === "undefined"
                  ? ""
                  : `${item.table_provider.name} logo`
              }
              loading="eager"
              width="20"
              height="20"
            />
          )}
          {type === "Region" && <RegionLogo name={item.name} />}
        </div>
        <DataSourcesListOption
          type={type}
          dataSource={item}
          searchValue={DOMPurify.sanitize(searchInputValue)}
        />
        <h5 className="SearchBar__autocomplete__list__sideType">{type}</h5>
      </Link>
    ));
};

const DataSourcesListOption = ({ type, dataSource, searchValue }) => {
  const formattedName = name => {
    const boldIndex = name.toLowerCase().indexOf(searchValue.toLowerCase());
    const firstPart = boldIndex === -1 ? name : name.substring(0, boldIndex);
    const boldPart =
      boldIndex === -1 ? "" : name.substring(boldIndex, boldIndex + searchValue.length);
    const secondPart =
      boldIndex === -1 ? "" : name.substring(boldIndex + searchValue.length, name.length);
    return (
      <p className="SearchBar__formattedName">
        {firstPart}
        <span className="SearchBar__formattedName--bold">{boldPart}</span>
        {secondPart}
      </p>
    );
  };

  if (type === "Attribute") {
    return (
      <div className="attribute">
        {formattedName(toNaturalSentence(dataSource.table_provider.name))}
        {formattedName(dataSource.name)}
      </div>
    );
  }

  return formattedName(
    dataSource.alias ? toNaturalSentence(dataSource.alias) : toNaturalSentence(dataSource.name),
  );
};

export const SearchBar = () => {
  const router = useRouter();
  // const dispatch = useDispatch();
  const searchString = useSelector(state =>
    productsCatalogSelectors.getSearchParams(state, "search"),
  );
  const axiosSource = useAxiosSource();
  const [selectedItem, setSelectedItem] = useState(-1);
  const [showList, setShowList] = useState(false);
  const [searchListContentNumbers, setSearchListContentNumbers] = useState([0, 0, 0, 0]);
  const [searchList, setSearchList] = useState([]);
  const [searchListLoading, setSearchListLoading] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState(searchString || "");
  const debouncedSearchInputValue = useDebounce(searchInputValue, 500);
  const userData = useSelector(userSelectors.getUserData);

  const onSearchInputChange = event => {
    if (showList === false) setShowList(true);
    setSearchInputValue(event);
  };

  const hideList = e => {
    if (e?.target?.id !== "search") {
      // setSearchInputValue(searchString);
      setShowList(false);
    }
  };

  const onSearchListItemClick = () => {
    setShowList(false);
    setSearchInputValue("");
  };

  const onClearSearch = () => {
    setSearchInputValue("");
    let pathname = router.asPath.split("?")[0];
    if (router.asPath.indexOf("featured") !== -1) pathname = linksConstants.DATA_CATALOG_CONNECTORS;

    const currentPage = router.asPath;
    const isConnector = currentPage.includes(linksConstants.CONNECTOR_ROOT);
    const isUseCase = currentPage.includes(linksConstants.USE_CASE_PAGE_ROOT);
    const isRecipe = currentPage.includes(linksConstants.RECIPE_ROOT);

    if (isConnector || isUseCase || isRecipe) {
      // do nothing
    } else {
      router.replace({
        pathname,
        state: { fromSearchbar: true, fetchType: "clear" },
        query: {
          search: "",
          sort: "",
          direction: "",
          categories: [],
          regions: [],
          data_source: "",
          tags: [],
          providers_page: 1,
          use_cases_page: 1,
          api_templates_page: 1,
        },
      });
    }
  };

  const onSearchClick = () => {
    const pathname =
      router.pathname === "/app/search-results/[view]"
        ? router.asPath.split("?")[0]
        : linksConstants.ALL_SEARCH_RESULTS;
    router.replace({
      pathname,
      state: { fromSearchbar: true, fetchType: "search" },
      query: {
        search: DOMPurify.sanitize(searchInputValue),
        sort: "",
        direction: "",
        categories: [],
        regions: [],
        data_source: "",
        tags: [],
        providers_page: 1,
        use_cases_page: 1,
        api_templates_page: 1,
      },
    });
    sendGaEvent(
      {
        action: `Query "${searchInputValue}"`,
        category: "Connector Search",
      },
      userData?.user?.email,
    );
  };

  const resetHighlights = () => {
    const list = document.getElementById("SearchBar_List");
    if (list) {
      const results = list.getElementsByTagName("a");
      const length = results.length + 1;
      for (let i = 0; i < length - 1; i++) {
        results[i].classList.remove("focused");
      }
    }
  };

  const hoverOverItem = index => {
    setSelectedItem(index);
  };

  const arrowKeysPressed = e => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") e.preventDefault();
    const list = document.getElementById("SearchBar_List");

    if (list) {
      if (e.key === "ArrowDown") {
        if (selectedItem == searchList.length - 1) {
          setSelectedItem(-1);
        } else if (selectedItem < searchList.length - 1) {
          setSelectedItem(selectedItem + 1);
        }
      }
      if (e.key === "ArrowUp") {
        if (selectedItem > -1) {
          setSelectedItem(selectedItem - 1);
        } else {
          setSelectedItem(searchList.length - 1);
        }
      }
    }

    if (e.key === "Enter") {
      const isActive = document?.activeElement?.id === "search";
      if (showList || isActive) {
        setShowList(false);
        if (selectedItem === -1) {
          onSearchClick();

          sendGaEvent(
            {
              action: `Query "${searchInputValue}"`,
              category: "Connector Search",
            },
            userData?.user?.email,
          );
        } else {
          setSearchInputValue("");
          const allItems = document.getElementsByClassName("SearchBar__autocomplete__list__item ");
          if (typeof allItems !== "undefined") {
            const clickedElement = allItems[selectedItem];
            if (typeof clickedElement !== "undefined") {
              clickedElement.click();
            }
          }

          sendGaEvent(
            {
              action: `Autocomplete Partner "${searchList[selectedItem].name}"`,
              category: "Connector Search",
            },
            userData?.user?.email,
          );
        }
      }
    }
  };

  useEffect(() => {
    resetHighlights();
    const list = document.getElementById("SearchBar_List");
    const searchAll = document.getElementById("SearchBar_SearchAll");
    if (list) {
      const results = list.getElementsByTagName("a");

      if (results[selectedItem]) {
        results[selectedItem]?.classList.add("focused");
        searchAll?.classList.remove("focused");
      } else {
        searchAll?.classList.add("focused");
      }
    } else {
      searchAll?.classList.add("focused");
    }
  }, [selectedItem]);

  useEffect(() => {
    if (debouncedSearchInputValue !== "") {
      setSelectedItem(-1);
      const loadList = async () => {
        axiosSource.cancel();
        axiosSource.setSource();
        try {
          setSearchListLoading(true);
          const { data } = await productsCatalogApi.autocomplete(
            DOMPurify.sanitize(debouncedSearchInputValue),
            {
              cancelToken: axiosSource.getToken(),
            },
          );

          // dispatch(allUseCasesActions.setUseCaseSearch(data.data_apis));

          if (!data.attributes) data.attributes = [];
          else if (data.attributes.length > 0) {
            data.attributes = data.attributes.filter(a => !!a.table_provider);
          }
          let dataToSet = [];
          let dataNumbers = [];
          dataToSet = [
            ...data.data_apis,
            ...data.api_templates,
            ...data.providers,
            ...data.data_sources,
            ...data.data_regions,
            ...data.attributes,
          ];
          dataNumbers = [
            data.data_apis.length,
            data.api_templates.length,
            data.providers.length,
            data.data_sources.length,
            data.data_regions.length,
            data.attributes.length,
          ];
          // That fragment exists solely to remove Blank recipe from results,
          // The same one exists in apiTemplatesCatalogSlice.js
          if (dataToSet.findIndex(item => item.name === "Blank") !== -1) {
            dataToSet = dataToSet.filter(item => item.name !== "Blank");
            dataNumbers[0] -= 1;
          }
          // End of that specific fragment
          setSearchList(dataToSet);
          setSearchListContentNumbers(dataNumbers);
          setSearchListLoading(false);
        } catch (e) {
          if (!Axios.isCancel(e)) {
            setSearchListLoading(false);
          }
        }
      };
      loadList();
    }

    return () => setSearchListLoading(false);
  }, [debouncedSearchInputValue]);

  useEffect(() => {
    setSearchInputValue(searchString || "");
  }, [searchString]);

  useEffect(() => {
    setSearchInputValue(searchInputValue);
  }, [router.query]);

  useEffect(() => {
    if (searchString !== "" && searchInputValue === "") {
      onClearSearch();
    }
  }, [searchInputValue]);

  return (
    <section className="SearchBar">
      <AnimatePresence>
        {searchInputValue.length >= 2 && showList === true && (
          <motion.div {...animatePresenceConfig}>
            <p className="SearchBar__autocomplete__info">Hit enter</p>
          </motion.div>
        )}
      </AnimatePresence>
      <Input
        autoComplete="off"
        id={"search"}
        name={"search"}
        value={searchInputValue}
        defaultValue={""}
        type="search"
        onChange={onSearchInputChange}
        placeholder={"Search Demyst Platform"}
        leftIconSrc={<FiSearch />}
        variant="regular"
        onClick={() => setShowList(true)}
        onKeyDown={e => arrowKeysPressed(e)}
        data-testid="catalog_search"
      />
      <AnimatePresence>
        {showList && (
          <ClickAwayListener onClickAway={e => hideList(e)}>
            <motion.div {...animatePresenceConfig} className="MotionElementPopupZIndex">
              {searchInputValue.length >= 2 && (
                <div className="SearchBar__autocomplete">
                  {(searchListLoading || searchInputValue !== debouncedSearchInputValue) && (
                    <Loader className="SearchBar__autocomplete__loading" />
                  )}
                  {!searchListLoading &&
                    searchList.length !== 0 &&
                    searchInputValue === debouncedSearchInputValue && (
                      <ul
                        className="SearchBar__autocomplete__list"
                        id="SearchBar_List"
                        data-testid="searchbar_list"
                      >
                        <SearchBarList
                          type={"Data API"}
                          searchList={searchList}
                          onSearchListItemClick={onSearchListItemClick}
                          hoverOverItem={hoverOverItem}
                          searchInputValue={searchInputValue}
                          contentNumbers={searchListContentNumbers}
                          desiredContent={0}
                          setShowList={setShowList}
                        />
                        <SearchBarList
                          type={"API Recipe"}
                          searchList={searchList}
                          onSearchListItemClick={onSearchListItemClick}
                          hoverOverItem={hoverOverItem}
                          searchInputValue={searchInputValue}
                          contentNumbers={searchListContentNumbers}
                          desiredContent={1}
                          setShowList={setShowList}
                        />
                        <SearchBarList
                          type={"Connector"}
                          searchList={searchList}
                          onSearchListItemClick={onSearchListItemClick}
                          hoverOverItem={hoverOverItem}
                          searchInputValue={searchInputValue}
                          contentNumbers={searchListContentNumbers}
                          desiredContent={2}
                          setShowList={setShowList}
                        />
                        <SearchBarList
                          type={"Source"}
                          searchList={searchList}
                          onSearchListItemClick={onSearchListItemClick}
                          hoverOverItem={hoverOverItem}
                          searchInputValue={searchInputValue}
                          contentNumbers={searchListContentNumbers}
                          desiredContent={3}
                          setShowList={setShowList}
                          setSearchInputValue={setSearchInputValue}
                        />
                        <SearchBarList
                          type={"Region"}
                          searchList={searchList}
                          onSearchListItemClick={onSearchListItemClick}
                          hoverOverItem={hoverOverItem}
                          searchInputValue={searchInputValue}
                          contentNumbers={searchListContentNumbers}
                          desiredContent={4}
                          setShowList={setShowList}
                          setSearchInputValue={setSearchInputValue}
                        />
                        <SearchBarList
                          type={"Attribute"}
                          searchList={searchList}
                          onSearchListItemClick={onSearchListItemClick}
                          hoverOverItem={hoverOverItem}
                          searchInputValue={searchInputValue}
                          contentNumbers={searchListContentNumbers}
                          desiredContent={5}
                          setShowList={setShowList}
                          setSearchInputValue={setSearchInputValue}
                        />
                      </ul>
                    )}
                  <ul className="SearchBar__autocomplete__list">
                    <li id="SearchBar_SearchAll" onMouseEnter={() => hoverOverItem(-1)}>
                      <button
                        id="SearchBar_SearchAll_button"
                        onClick={onSearchClick}
                        onKeyDown={e => arrowKeysPressed(e)}
                      >
                        <p>Search {searchInputValue}</p>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </motion.div>
          </ClickAwayListener>
        )}
      </AnimatePresence>
    </section>
  );
};
