import { Button } from "../../../../components/buttons/Button";
import { FiEyeOff } from "react-icons/fi";
import { notify } from "../../../../utils/notify";
import { operationalDashboardApi } from "../../../../../api/operationalDashboardApi";
import { serializeError } from "../../../../../utils/serializeError";
import { linksConstants } from "../../../../../constants/linksConstants";
import { useCasesThunks } from "../../../../../redux/useCases/singleUseCaseSlice";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";

export const ChangeVisibility = ({ useCase }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const sendChangeVisibilityRequest = async () => {
    const { useCaseId } = router.query;
    try {
      let response = await operationalDashboardApi.changeUseCaseVisibility(useCaseId);
      notify.success(response.data.message);
      setTimeout(() => {
        const fetch = async () => {
          await dispatch(useCasesThunks.fetchSingleUseCase({ useCaseId: useCaseId }));
        };
        fetch();
        router.push(linksConstants.ALL_USE_CASES);
      }, 1000);
    } catch (e) {
      notify.error(serializeError(e).message);
    }
  };

  return (
    <div className="DownloadToCsv">
      <Button onClick={sendChangeVisibilityRequest} variant={"whiteWithShadow"}>
        <FiEyeOff />
        {useCase.active ? "Archive" : "Unarchived"}
      </Button>
    </div>
  );
};
