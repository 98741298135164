import { FreeUserBar } from "./FreeUserBar";
import { ContactModal } from "./ContactModal";
import { userSelectors } from "../../redux/user/userSelectors";
import { useSelector } from "react-redux";
import { ToastContainer, Zoom } from "react-toastify";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { animatePresenceConfig, userPlans } from "../../config/appConfig";
import { Navigation } from "./Navigation/Navigation";
import Provider from "../../redux/Provider";
import { HelmetProvider } from "react-helmet-async";
import { useEffect, useState } from "react";
import { linksConstants } from "../../constants/linksConstants";
import { useRouter } from "next/router";
import { UnverifiedDrawer } from "./UnverifiedDrawer";
import { userApi } from "../../api/userApi";
import { useDispatch } from "react-redux";
import { userActions } from "../../redux/user/userSlice";
import { AppHeader } from "./AppHeader";
import { Loader } from "../components/Loader/Loader";
import { trackPageView } from "../../config/userTracking";
import usePathChange from "../../utils/hooks/usePathChange";
import DOMPurify from "isomorphic-dompurify";

function Layout({ children, permissions, isAuthRequired }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(userSelectors.isUserLoggedIn);
  const isTosOutdated = useSelector(userSelectors.isTosOutdated);
  const userData = useSelector(userSelectors.getUserData);
  const hasUserBeenLoggedOutManually = useSelector(userSelectors.hasUserBeenLoggedOutManually);
  const contactUsModalOpen = useSelector(userSelectors.getContactUsModalState);
  const userPlan = useSelector(userSelectors.getUserPlan);
  const [loading, setLoading] = useState(!isUserLoggedIn);
  const pathname = usePathChange();

  useEffect(() => {
    (async () => {
      try {
        const { data: userData } = await userApi.getUserData();
        dispatch(userActions.setUserData({ userData }));
      } catch (e) {
        console.log("No user logged in");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (pathname) {
      const cleanPathname = DOMPurify.sanitize(pathname);
      trackPageView(cleanPathname, "Platform");
    }
  }, [pathname]);

  if (typeof window !== "undefined" && !loading) {
    if (isAuthRequired && !isUserLoggedIn) {
      router.push({
        pathname: linksConstants.LOGIN,
        ...(!hasUserBeenLoggedOutManually && {
          state: { redirectTo: window.location },
        }),
      });
    }

    if (isAuthRequired && isUserLoggedIn && userData?.user?.needs_to_change_password) {
      router.push(linksConstants.CHANGE_PASSWORD);
    }

    if (
      isUserLoggedIn &&
      userData?.organization?.require_two_factor_auth &&
      !userData?.user?.two_factor_auth_active &&
      router?.asPath !== linksConstants.SETTINGS_PROFILE
    ) {
      router.push(linksConstants.SETTINGS_PROFILE);
    }

    if (isTosOutdated) {
      router.push(linksConstants.TOS_ACCEPTANCE);
    }

    if (permissions && permissions.length > 0 && isUserLoggedIn) {
      const { permissions: userPermissions } = userData.user;
      const haveAllPermissions = permissions.reduce(
        (havePrevPermissions, currentPermission) =>
          havePrevPermissions && userPermissions[currentPermission],
        true,
      );

      if (!haveAllPermissions) {
        router.replace(linksConstants.ALL_USE_CASES);
      }
    }
  }

  return (
    <>
      <AnimatePresence>
        {contactUsModalOpen && (
          <motion.div className="MotionElementModalZIndex" {...animatePresenceConfig}>
            <ContactModal />
          </motion.div>
        )}
      </AnimatePresence>
      <FreeUserBar userPlan={userPlan} />
      {isUserLoggedIn && router.pathname !== "/app/settings/general" && <UnverifiedDrawer />}
      <div className={clsx("PlatformLayout", userPlan === userPlans.LEAD && "small")}>
        <Navigation />
        <ToastContainer
          position={"top-center"}
          transition={Zoom}
          autoClose={false}
          hideProgressBar
          closeButton={false}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
        />
        <div className="PlatformLayout__wrapper">
          <AppHeader />
          {loading ? (
            <div className="PlatformLayout__loader">
              <Loader />
            </div>
          ) : (
            <div className="PlatformLayout__content">{children}</div>
          )}
        </div>
      </div>
    </>
  );
}

export default function PlatformLayout({
  children,
  initialReduxState,
  permissions,
  isAuthRequired,
}) {
  return (
    <Provider.Redux initialReduxState={initialReduxState}>
      <HelmetProvider>
        <Layout isAuthRequired={isAuthRequired} permissions={permissions}>
          {children}
        </Layout>
      </HelmetProvider>
    </Provider.Redux>
  );
}
