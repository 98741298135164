export function DemystLogoMobile() {
  return (
    <svg width="24" height="40" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.23483 5.99324C7.20631 6.46015 7.03065 6.99462 6.15689 7.26786C4.6901 7.72576 3.85687 8.73915 3.5386 9.05442C3.14375 9.44627 2.83298 9.98974 2.61529 10.7389C2.34505 11.6637 2.10484 12 1.2611 12C0.564494 12 0 11.4355 0 10.7389C0 10.0423 0.415864 9.57238 1.2611 9.47779C3.25935 9.2556 4.71262 7.93294 4.71262 6.02627V5.97523C4.71262 4.06856 3.25935 2.7459 1.2611 2.52371C0.415864 2.42762 0 1.95771 0 1.2611C0 0.564494 0.564494 0 1.2611 0C2.10484 0 2.49668 0.48042 2.6288 1.2611C2.73689 1.90066 3.14375 2.55373 3.5386 2.94558C3.85687 3.26235 4.6901 4.27424 6.15689 4.73214C7.03065 5.00388 7.20631 5.53985 7.23483 6.00525V5.99324Z"
        fill="#17009A"
      />
      <path
        d="M0.917599 7.25992H1.18633C1.86193 7.25992 2.45344 6.75097 2.49398 6.07688C2.53752 5.34574 1.95801 4.73771 1.23438 4.73771C1.23438 4.73771 0.959636 4.7302 0.881567 4.73771C0.594816 4.76473 0.383131 4.96891 0.344097 5.24966C0.291551 5.63249 0.291551 6.34862 0.344097 6.71494C0.389136 7.0122 0.605326 7.23289 0.917599 7.25992Z"
        fill="#17009A"
      />
    </svg>
  );
}
