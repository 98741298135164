import { FiInfo } from "react-icons/fi";
import { useSelector } from "react-redux";
import { userSelectors } from "../../redux/user/userSelectors";
import { userPlans } from "../../config/appConfig";
import UnverifiedForm from "./UnverifiedForm";
import { useEffect, useState } from "react";
import { useMatchMedia } from "@wojtekmaj/react-hooks";

export const UnverifiedButton = ({ isSearchVisible = false }) => {
  const [response, setResponse] = useState("");
  const [showModal, setShowModal] = useState(false);
  const isDesktop = useMatchMedia("screen and (min-width: 960px)");
  const isSmallMobile = useMatchMedia("screen and (max-width: 300px)");
  const userPlan = useSelector(userSelectors.getUserPlan);

  useEffect(() => {
    if (response !== "") {
      setTimeout(() => {
        setResponse("");
      }, 2000);
    }
  }, [response]);

  return (
    userPlan === userPlans.UNVERIFIED &&
    !isSearchVisible && (
      <>
        {response !== "" && <p className="UnverifiedButton__response">{response}</p>}
        <UnverifiedForm
          setResponse={setResponse}
          open={showModal}
          onBackdropClick={() => setShowModal(false)}
        />
        <button
          onClick={() => setShowModal(true)}
          className={!isSmallMobile ? "UnverifiedButton" : "smallScreenButton"}
        >
          <FiInfo />

          {!isSmallMobile && (
            <section>
              <div className="UnverifiedButton__text">
                {isDesktop && (
                  <p>
                    {`Your organization is `}
                    <span>Unverified</span>
                  </p>
                )}
                {!isDesktop && (
                  <p>
                    <span>Unverified</span>
                  </p>
                )}
              </div>
              {isDesktop && <p className="UnverifiedButton__cta">Click here to contact sales</p>}
            </section>
          )}
        </button>
      </>
    )
  );
};
