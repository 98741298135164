import { createSelector } from "@reduxjs/toolkit";

const getProductsFetchStatus = createSelector(
  state => state.productsCatalog.products.fetchStatus,
  fetchStatus => fetchStatus,
);

const getProductsError = createSelector(
  state => state.productsCatalog.products.error,
  error => error,
);

const getProductsEntities = createSelector(
  state => state.productsCatalog.products.data.entities,
  entities => entities,
);

const getProductsIds = createSelector(
  state => state.productsCatalog.products.data.ids,
  ids => ids,
);

const getProductsList = createSelector(
  [getProductsIds, getProductsEntities],
  (productsIds, productsEntities) => {
    return productsIds.map(id => {
      if (productsEntities[id]) {
        return productsEntities[id];
      }
    });
  },
);

const getSearchParams = createSelector(
  [state => state.productsCatalog.products.searchParams, (state, paramName) => paramName],
  (searchParams, paramName) => {
    if (paramName) {
      return searchParams[paramName];
    } else {
      return searchParams;
    }
  },
);

const getPagination = createSelector(
  [
    state => state.productsCatalog.products.searchParams.page,
    state => state.productsCatalog.products.totalPages,
    state => state.productsCatalog.products.totalEntries,
  ],
  (currentPage, totalPages, totalEntries) => {
    return {
      currentPage,
      totalPages,
      totalEntries,
    };
  },
);

const getShowFilters = createSelector(
  state => state.productsCatalog.showFilters,
  showFilters => showFilters,
);

const getTags = createSelector(
  state => state.productsCatalog.tags,
  tags => tags,
);

const getTagIds = createSelector(
  state => state.productsCatalog.tagIds,
  tagIds => tagIds,
);

export const productsCatalogSelectors = {
  getProductsFetchStatus,
  getProductsError,
  getProductsEntities,
  getProductsIds,
  getProductsList,
  getSearchParams,
  getPagination,
  getShowFilters,
  getTags,
  getTagIds,
};
