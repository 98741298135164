export const formAttribution = body => {
  const landingPage = localStorage.getItem("landing_page");
  const landingPageDatetime = localStorage.getItem("landing_page_datetime");
  const landingPageFullUrl = localStorage.getItem("landing_page_full_url");

  const currentPagePath = localStorage.getItem("current_page_path");
  const currentPagePathDatetime = localStorage.getItem("current_page_path_datetime");

  const prevPagePath = localStorage.getItem("prev_page_path");
  const prevPagePathDatetime = localStorage.getItem("prev_page_path_datetime");

  const pageFlow = JSON.parse(localStorage.getItem("page_flow"));
  const sessionPageFlow = JSON.parse(sessionStorage.getItem("session_page_flow"));

  const gaAdCookie = localStorage.getItem("gaAd");
  const gaAdCookieUpdated = localStorage.getItem("gaAd_updated");
  const gaAdCookieCreated = localStorage.getItem("gaAd_created");
  const isProductKickingAss =
    landingPage?.startsWith("/external-data/") || landingPage?.startsWith("/third-party-data/");

  const googleAdsGclid = localStorage.getItem("gclid");
  const googleAdsGclidCreated = localStorage.getItem("gclid_created");
  const googleAdsGclidUpdated = localStorage.getItem("gclid_updated");

  const linkedInCookie = localStorage.getItem("li_fat_id");
  const linkedInCookieCreated = localStorage.getItem("li_fat_id_created");
  const linkedInCookieUpdated = localStorage.getItem("li_fat_id_updated");

  const utmMedium = localStorage.getItem("utm_medium");
  const utmMediumCreated = localStorage.getItem("utm_medium_created");
  const utmMediumUpdated = localStorage.getItem("utm_medium_updated");

  const utmSource = localStorage.getItem("utm_source");
  const utmSourceCreated = localStorage.getItem("utm_source_created");
  const utmSourceUpdated = localStorage.getItem("utm_source_updated");

  const utmCampaign = localStorage.getItem("utm_campaign");
  const utmCampaignCreated = localStorage.getItem("utm_campaign_created");
  const utmCampaignUpdated = localStorage.getItem("utm_campaign_updated");

  const utmContent = localStorage.getItem("utm_content");
  const utmContentCreated = localStorage.getItem("utm_content_created");
  const utmContentUpdated = localStorage.getItem("utm_content_updated");

  const utmTerm = localStorage.getItem("utm_term");
  const utmTermCreated = localStorage.getItem("utm_term_created");
  const utmTermUpdated = localStorage.getItem("utm_term_updated");

  const marketingSource = linkedInCookie ? "linkedin" : googleAdsGclid ? "google(cpc)" : null;

  return {
    body,

    ...(landingPage && { landing_page: landingPage }),
    ...(landingPageFullUrl && { landing_page_full_url: landingPageFullUrl }),
    ...(landingPageDatetime && { landing_page_datetime: landingPageDatetime }),

    ...(currentPagePath && { current_page_path: currentPagePath }),
    ...(currentPagePathDatetime && { current_page_path_datetime: currentPagePathDatetime }),

    ...(prevPagePath && { prev_page_path: prevPagePath }),
    ...(prevPagePathDatetime && { prev_page_path_datetime: prevPagePathDatetime }),

    ...(sessionPageFlow && { session_page_flow: sessionPageFlow }),
    ...(pageFlow && { macro_page_flow: pageFlow }),

    ...(utmMedium && { utm_medium: utmMedium }),
    ...(utmMediumCreated && { utm_medium_created: utmMediumCreated }),
    ...(utmMediumUpdated && { utm_medium_updated: utmMediumUpdated }),
    ...(utmSource && { utm_source: utmSource }),
    ...(utmSourceCreated && { utm_source_created: utmSourceCreated }),
    ...(utmSourceUpdated && { utm_source_updated: utmSourceUpdated }),
    ...(utmCampaign && { utm_campaign: utmCampaign }),
    ...(utmCampaignCreated && { utm_campaign_created: utmCampaignCreated }),
    ...(utmCampaignUpdated && { utm_campaign_updated: utmCampaignUpdated }),
    ...(utmContent && { utm_content: utmContent }),
    ...(utmContentCreated && { utm_content_created: utmContentCreated }),
    ...(utmContentUpdated && { utm_content_updated: utmContentUpdated }),
    ...(utmTerm && { utm_term: utmTerm }),
    ...(utmTermCreated && { utm_term_created: utmTermCreated }),
    ...(utmTermUpdated && { utm_term_updated: utmTermUpdated }),

    ...(marketingSource && { marketing_source: marketingSource }),

    ...(gaAdCookie && { gaAd: gaAdCookie }),
    ...(gaAdCookieCreated && { gaAd_created: gaAdCookieCreated }),
    ...(gaAdCookieUpdated && { gaAd_updated: gaAdCookieUpdated }),
    ...(isProductKickingAss && { pikYa: isProductKickingAss }),

    ...(googleAdsGclid && { gclid: googleAdsGclid }),
    ...(googleAdsGclidCreated && { gclid_created: googleAdsGclidCreated }),
    ...(googleAdsGclidUpdated && { gclid_updated: googleAdsGclidUpdated }),

    ...(linkedInCookie && { li_fat_id: linkedInCookie }),
    ...(linkedInCookieCreated && { li_fat_id_created: linkedInCookieCreated }),
    ...(linkedInCookieUpdated && { li_fat_id_updated: linkedInCookieUpdated }),

    ...(document.referrer && { referrer: document.referrer }),
  };
};
