import PropTypes from "prop-types";
import clsx from "clsx";

import { createTestId } from "../../../utils/createTestId";
import { Button } from "./Button";
import Link from "next/link";

export function LinkButton({
  className,
  testId,
  variant,
  size,
  disabled,
  fullWidth,
  onClick,
  buttonProps = {},
  children,
  href,
  isExternal,
  ...props
}) {
  // You can't disable a link, so if you pass `disabled` prop this component just renders a button
  if (disabled) {
    return (
      <Button
        variant={variant}
        className={className}
        size={size}
        disabled={disabled}
        fullWidth={fullWidth}
        onClick={onClick}
        data-test-id={testId}
        {...buttonProps}
      >
        {children}
      </Button>
    );
  }
  if (isExternal) {
    return (
      <a
        className={clsx(
          "Button",
          variant && `Button--${variant}`,
          size && `Button--${size}`,
          "Button--linkButton",
          className,
        )}
        data-test-id={createTestId(testId)}
        onClick={onClick}
        href={href}
        {...props}
      >
        {children}
      </a>
    );
  } else {
    return (
      <Link
        href={href}
        className={clsx(
          "Button",
          variant && `Button--${variant}`,
          size && `Button--${size}`,
          "Button--linkButton",
          className,
        )}
        {...props}
        data-test-id={createTestId(testId)}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }
}

LinkButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  isExternal: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  testId: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  variant: PropTypes.oneOf([
    "outlined",
    "warning",
    "icon",
    "clear",
    "whiteWithShadow",
    "withShadow",
    "withBlueShadow",
    "noBorder",
  ]),
};
