import clsx from "clsx";
import { PropTypes } from "prop-types";

import Breadcrumbs from "@demystdata/ui/breadcrumbs";
import { linksConstants } from "../../../../constants/linksConstants";

export const UseCasePageHeader = ({ activeUseCase, activeChannelsIds, className, children }) => {
  const channel = activeUseCase.channels.find(
    channel => channel.id === Number(activeChannelsIds[0]),
  );

  const breadCrumbItems = [
    { href: linksConstants.ALL_USE_CASES, label: "Data APIs" },
    { href: null, label: activeUseCase?.name },
  ];

  return (
    <section className={clsx("UseCasePageHeader", className)}>
      <section className="UseCasePageHeader__breadcrumbs">
        <Breadcrumbs items={breadCrumbItems} variant="tertiary" />
      </section>
      <section className="UseCasePageHeader__info">
        <h2 className="UseCasePageHeader__info__title">
          <span>
            {activeUseCase?.name}
            <span className="UseCasePageHeader__info__title__hash">{` #${activeChannelsIds[0]}`}</span>
          </span>
          {channel?.billable_category === "evaluation" && (
            <span
              className={clsx(
                "UseCasePageHeader__info__title__label",
                "UseCasePageHeader__info__title__label--evaluation",
              )}
            >
              Evaluation API
            </span>
          )}
          {channel?.billable_category === "production" && (
            <span
              className={clsx(
                "UseCasePageHeader__info__title__label",
                "UseCasePageHeader__info__title__label--production",
              )}
            >
              Production API
            </span>
          )}
        </h2>
        {children}
      </section>
    </section>
  );
};

UseCasePageHeader.propTypes = {
  activeUseCase: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  subtitle: PropTypes.string,
};
