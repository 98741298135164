import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { singleUseCaseSelectors } from "../../../../redux/useCases/singleUseCaseSelectors";
import { useCasesActions } from "../../../../redux/useCases/singleUseCaseSlice";
import { Select } from "../../../components/formComponents";
import { toArray } from "../../../../utils/helpers";
import PropTypes from "prop-types";

export const ChannelSwitcher = ({ allowAllChannels = false }) => {
  const dispatch = useDispatch();

  const channels = useSelector(singleUseCaseSelectors.getActiveUseCaseChannels);
  const activeChannelsIds = useSelector(singleUseCaseSelectors.getActiveChannelsIds);
  const allChannelsSelected = useSelector(singleUseCaseSelectors.allChannelsSelected);

  const [selectedChannelId, setSelectedChannelId] = useState(activeChannelsIds[0] || "");

  useEffect(() => {
    if (allChannelsSelected && allowAllChannels) {
      setSelectedChannelId("ALL");
    } else {
      setSelectedChannelId(activeChannelsIds[0]);
    }
  }, [activeChannelsIds, allChannelsSelected]);

  const onChannelChange = event => {
    if (event.target.value === "ALL" && allowAllChannels) {
      return dispatch(useCasesActions.selectAllChannels());
    }
    if (event.target.value !== "") {
      return dispatch(
        useCasesActions.setActiveChannelsIds({ channelsIds: toArray(event.target.value) }),
      );
    }
  };

  return (
    <div className="ChannelSwitcher">
      <Select
        id="channelSelect"
        name="channelSelect"
        value={selectedChannelId}
        onChange={onChannelChange}
        variant={"whiteWithShadow"}
      >
        <option value="" disabled>
          Select API Channel
        </option>
        {allowAllChannels && <option value="ALL">All APIs</option>}
        {channels.map(channel => {
          return (
            <option key={channel.id} value={channel.id}>
              {channel.aka || channel.name}
            </option>
          );
        })}
      </Select>
    </div>
  );
};

ChannelSwitcher.propTypes = {
  allowAllChannels: PropTypes.bool,
};
