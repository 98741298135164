import { createSelector } from "@reduxjs/toolkit";
import sortBy from "lodash.sortby";
import { isSameDay } from "date-fns";
import { getSortedChannels } from "./helpers";
import createCachedSelector from "re-reselect";

// --- USE CASES
const getUseCasesEntities = createSelector(
  [state => state.singleUseCase.useCases.entities],
  entities => entities,
);
const getUseCasesIds = createSelector(
  [
    state => state.singleUseCase.useCases.demoUseCasesIds,
    state => state.singleUseCase.useCases.liveUseCasesIds,
    (state, type) => type,
  ],
  (demoIds, liveIds, type) => {
    if (type) {
      switch (type) {
        case "demo": {
          return demoIds;
        }
        case "live": {
          return liveIds;
        }
      }
    }
    return [...demoIds, liveIds];
  },
);

const getUseCasesList = createSelector([state => state.singleUseCase.useCases], useCases => {
  return [...useCases.demoUseCasesIds, ...useCases.liveUseCasesIds].map(id => {
    if (useCases.entities[id]) {
      return useCases.entities[id];
    }
  });
});

const getUseCaseById = createCachedSelector(
  [getUseCasesEntities, (state, useCaseId) => useCaseId],
  (useCases, useCaseId) => useCases[useCaseId],
)((useCases, useCaseId) => useCaseId);

const getUseCaseDefaultChannel = createSelector(getUseCaseById, useCase => {
  if (useCase) {
    return getSortedChannels(useCase.channels)?.[0];
  }
});

const getDefaultChannelQueriesData = createSelector(
  getUseCaseById,
  useCase => useCase?.defaultChannelQueriesData,
);

const shouldUpdateDefaultChannelQueriesData = createSelector(getUseCaseById, useCase => {
  if (!useCase?.defaultChannelQueriesDataCacheTimestamp) return true;
  return !isSameDay(new Date(useCase.defaultChannelQueriesDataCacheTimestamp), new Date());
});

const getDefaultChannelQueriesDataProvidersLogos = createSelector(
  getUseCaseById,
  useCase => useCase.defaultChannelQueriesDataProvidersLogos,
);

const getUseCasesPagesDateRange = createSelector(
  [state => state.singleUseCase.startDate, state => state.singleUseCase.endDate],
  (startDate, endDate) => [startDate, endDate],
);

// --- ACTIVE USE CASE

const getActiveUseCase = createSelector(
  [getUseCasesEntities, state => state.singleUseCase.activeUseCase.id],
  (useCases, activeUseCaseId) => useCases[activeUseCaseId],
);

const getActiveUseCaseFetchStatus = createSelector(
  state => state.singleUseCase.activeUseCase.fetchStatus,
  fetchStatus => fetchStatus,
);

const getActiveUseCaseError = createSelector(
  state => state.singleUseCase.activeUseCase.error,
  error => error,
);

const getActiveUseCaseChannels = createSelector([getActiveUseCase], activeUseCase => {
  return sortBy(activeUseCase?.channels, "position");
});

const getActiveChannelsIds = createSelector(
  state => state.singleUseCase.activeUseCase.activeChannelsIds,
  activeChannelsIds => activeChannelsIds,
);

const getActiveChannel = createSelector(
  [getActiveUseCaseChannels, getActiveChannelsIds],
  (channels, channelsIds) => {
    return channels.find(channel => {
      return channel.id === Number(channelsIds[0]);
    });
  },
);

const allChannelsSelected = createSelector(
  [getActiveUseCaseChannels, getActiveChannelsIds],
  (channels, activeChannelsIds) => {
    // TODO: Might need some better logic for checking this
    return channels?.length === activeChannelsIds?.length;
  },
);

const getActiveUseCaseMinDate = createSelector(
  [getActiveUseCase],
  useCase => useCase?.date_implemented,
);

const isAnyChannelConfigurable = createSelector(getActiveUseCaseChannels, channels => {
  if (channels) {
    return channels.some(channel => Boolean(channel.configuration));
  } else {
    return false;
  }
});

const isAnyChannelLookupable = createSelector(getActiveUseCaseChannels, channels => {
  if (channels) {
    return channels.some(channel => Boolean(channel.single_lookupable));
  } else {
    return false;
  }
});

// --- REPORTS

const getActiveReport = createSelector(
  [
    getActiveChannel,
    state => state.singleUseCase.reports.activeReportId,
    state => Object.values(state.singleUseCase.dataProducts.providers.entities),
  ],
  (activeChannel, activeReportId, dataProducts) => {
    const cumulativeReport = {
      type: "CUMULATIVE",
      id: "cumulative",
      name: "Cumulative Report",
    };
    if (activeReportId === "cumulative") {
      return cumulativeReport;
    } else {
      const provider = activeChannel.table_providers.find(
        provider => String(provider.id) === String(activeReportId),
      );
      const product = dataProducts.find(product => String(product.id) === String(activeReportId));
      if (provider) {
        return {
          type: "PROVIDER",
          id: provider.id,
          name: provider.name,
          dataSourceName: provider.data_source?.name,
          logo: provider.logo,
        };
      } else if (product) {
        return {
          type: "PROVIDER",
          id: product.id,
          name: product.name,
          dataSourceName: product.data_source?.name,
          logo: product.logo,
        };
      } else {
        return cumulativeReport;
      }
    }
  },
);

// --- DATA PRODUCTS
const getDataProductsFetchStatus = createSelector(
  state => state.singleUseCase.dataProducts.fetchStatus,
  fetchStatus => fetchStatus,
);

const getDataProductsError = createSelector(
  state => state.singleUseCase.dataProducts.error,
  error => error,
);

const getProvidersEntities = createSelector(
  state => state.singleUseCase.dataProducts.providers.entities,
  providersEntities => providersEntities,
);

const getProvidersNames = createSelector(
  state => state.singleUseCase.dataProducts.providers.names,
  providersNames => providersNames,
);

const getProvidersList = createSelector(
  [getProvidersEntities, getProvidersNames],
  (entities, names) => {
    return names.map(name => {
      if (entities[name]) {
        return entities[name];
      }
    });
  },
);

const getActiveProvidersEntitiesList = createSelector(
  [getProvidersList, state => state.singleUseCase.reports.activeReportId],
  (providers, activeReportId) => {
    return providers.filter(provider => provider.id === activeReportId);
  },
);

const getActiveProvider = createSelector(
  [getActiveProvidersEntitiesList],
  activeProviders => activeProviders[0],
);

const getDataProductsMetricData = createSelector(
  [state => state.singleUseCase.dataProducts.metrics, (state, metric) => metric],
  (metrics, metricType) => {
    return metrics[metricType];
  },
);

// --- TRANSACTION PANEL

const isConfigurationEdited = createSelector(
  state => state.singleUseCase.transactionPanel.configuration.isEdited,
  isEdited => isEdited,
);

const getOverlayInfo = createSelector(
  [
    state => state.singleUseCase.transactionPanel.overlay.show,
    state => state.singleUseCase.transactionPanel.overlay.message,
  ],
  (showOverlay, overlayMessage) => {
    return {
      showOverlay,
      overlayMessage,
    };
  },
);

export const singleUseCaseSelectors = {
  // --- USE CASES
  getUseCasesEntities,
  getUseCasesIds,
  getUseCasesList,
  getUseCaseById,
  getUseCaseDefaultChannel,
  getDefaultChannelQueriesData,
  shouldUpdateDefaultChannelQueriesData,
  getDefaultChannelQueriesDataProvidersLogos,
  getUseCasesPagesDateRange,
  // --- ACTIVE USE CASE
  getActiveUseCase,
  getActiveUseCaseFetchStatus,
  getActiveUseCaseError,
  getActiveUseCaseChannels,
  getActiveChannelsIds,
  allChannelsSelected,
  getActiveUseCaseMinDate,
  getActiveChannel,
  isAnyChannelConfigurable,
  isAnyChannelLookupable,
  // --- REPORTS
  getActiveReport,
  // --- DATA PRODUCTS
  getDataProductsFetchStatus,
  getDataProductsError,
  getProvidersList,
  getActiveProvidersEntitiesList,
  getActiveProvider,
  getDataProductsMetricData,
  // --- TRANSACTION PANEL
  isConfigurationEdited,
  getOverlayInfo,
};
