import { useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import clsx from "clsx";

import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import { animatePresenceConfig } from "../../../config/appConfig";

export const Modal = ({
  open,
  onBackdropClick,
  backdropClassName,
  motionElementClassName,
  children,
}) => {
  const [renderPortalWrapper, setRenderPortalWrapper] = useState(false);
  const [renderContent, setRenderContent] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (open) {
      setRenderPortalWrapper(true);
      setRenderContent(true);
      setTimeout(() => {
        modalRef.current?.focus();
      }, 0);
    } else {
      setRenderContent(false);
      setTimeout(() => setRenderPortalWrapper(false), 200);
    }
  }, [open]);

  if (!renderPortalWrapper) return null;

  // TODO: Create focus trap

  return createPortal(
    <div className="Modal" role="presentation">
      <AnimatePresence>
        {renderContent && (
          <motion.div
            key={"modal"}
            className={clsx("Modal__motionElement", motionElementClassName)}
            {...animatePresenceConfig}
            ref={modalRef}
            tabIndex={-1}
            aria-modal="true"
            role="dialog"
          >
            <div
              className={clsx("Modal__backdrop", backdropClassName)}
              onClick={onBackdropClick}
              aria-hidden="true"
            />
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>,
    document.querySelector("#__next"),
  );
};

Modal.propTypes = {
  backdropClassName: PropTypes.string,
  children: PropTypes.node,
  motionElementClassName: PropTypes.string,
  onBackdropClick: PropTypes.func,
  open: PropTypes.bool,
};
