import { useState } from "react";
import { format } from "date-fns";
import { PropTypes } from "prop-types";
import clsx from "clsx";
import { FiChevronDown, FiCalendar } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";
import ClickAwayListener from "react-click-away-listener";
import { Button } from "../buttons/Button";
import { animatePresenceConfig } from "../../../config/appConfig";
import { Paper } from "../Paper/Paper";

import { getFormattedDateRange } from "../../../utils/getFormattedDateRange";

export const DateRangePickerComponent = ({ startDate, endDate, onApply, disabled }) => {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [internalStartDate, setInternalStartDate] = useState(startDate);
  const [internalEndDate, setInternalEndDate] = useState(endDate);

  const compareDates = (dateString1, dateString2, direction) => {
    let dates = [dateString2];
    const isFirstLater = dateString1 > dateString2;
    isFirstLater ? dates.push(dateString1) : dates.unshift(dateString1);
    return dates[direction];
  };

  const openDateRangePicker = () => {
    setShowDateRangePicker(true);
  };

  const closeDateRangePicker = event => {
    if (event) {
      if (event.target !== event.currentTarget) {
        return;
      }
    }
    setShowDateRangePicker(false);
    if (!internalStartDate || !internalEndDate) {
      return;
    } else {
      onApply([internalStartDate, internalEndDate]);
    }
  };

  const currentDate = format(new Date(), "yyyy-MM-dd");

  return (
    <div className="DateRangePickerComponent">
      <Button
        className={clsx(
          "DateRangePickerComponent__button",
          showDateRangePicker && "DateRangePickerComponent__button--active",
        )}
        variant={"whiteWithShadow"}
        onClick={openDateRangePicker}
        disabled={disabled}
      >
        <FiCalendar />
        <span>{getFormattedDateRange(startDate, endDate)}</span>
        <FiChevronDown />
      </Button>
      <AnimatePresence>
        {showDateRangePicker && (
          <ClickAwayListener onClickAway={() => closeDateRangePicker()}>
            <motion.div
              className="DateRangePickerComponent__popupWrapper"
              onClick={closeDateRangePicker}
              {...animatePresenceConfig}
            >
              <Paper
                showBorder={false}
                elevation={2}
                borderRadius={"md"}
                showArrow
                arrowPlacement={"top"}
                arrowClassName="DateRangePickerComponent__arrow"
              >
                <div className="DatePickerContainer">
                  <label htmlFor="start">Start date:</label>
                  <input
                    type="date"
                    id="start"
                    name="trip-start"
                    min="2010-01-01"
                    max={compareDates(internalEndDate, currentDate, 0)}
                    onChange={event => setInternalStartDate(event.target.value)}
                    value={internalStartDate}
                  />
                  <label htmlFor="start">End date:</label>
                  <input
                    type="date"
                    id="end"
                    name="trip-end"
                    min={compareDates("2010-01-01", internalStartDate, 1)}
                    max={currentDate}
                    onChange={event => setInternalEndDate(event.target.value)}
                    value={internalEndDate}
                  />
                </div>
              </Paper>
            </motion.div>
          </ClickAwayListener>
        )}
      </AnimatePresence>
    </div>
  );
};

DateRangePickerComponent.propTypes = {
  endDate: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  onApply: PropTypes.func,
  startDate: PropTypes.string,
};
